import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { CreateQueryStatus } from './api/getC4SActiveConnection'
import { Connection, getConnections, System } from './api/getConnections'

export const useConnections = (
	enabled: boolean,
	createConnectionStatus: CreateQueryStatus,
	systemType?: System
): UseQueryResult<Connection[]> => {
	const apiClient = useApi()
	const queryKey = systemType ? ['getConnections', systemType] : ['getConnections']
	const queryFunction = () => getConnections(apiClient, systemType)

	return useQuery(queryKey, queryFunction, {
		enabled: createConnectionStatus != CreateQueryStatus.Loading && enabled
	})
}
