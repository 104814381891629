import { AxiosResponse } from 'axios'
import i18n from '../../../i18n'
import { CreateSourceRequest, CreateSourceResponse, MapFieldDefinition } from '../../../types'
import { EntityType } from '../../../types/sources/EntityType'
import { ApiErrorState, setApiErrorAction } from '../../apiError'
import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import { updateCurrentProjectAction, updateLoadingNextStep } from '../actions'
import defaultContactFieldMapping from './default-field-mapping-4-contacts.json'
import defaultFieldMapping from './default-field-mapping.json'

export const createConnectManageSource =
	(passDelimiterInfo = false): AppThunk<Promise<void>> =>
	(dispatch: TDispatch, getState: TGetState): Promise<void> => {
		const apiClient = getApiClient4Thunks(dispatch)
		const url = `/pls/sources`

		const project = getState().projectWizard.currentProject
		const sourceName = getState().projectWizard.currentProject.source.name || project?.name + ' - source'

		if (project.id) {
			const request: CreateSourceRequest = {
				display_name: project.source.external_source_id || sourceName,
				project_id: project.id,
				field_definitions_record:
					project.source.entityType === EntityType.CONTACTS
						? defaultContactFieldMapping
						: defaultFieldMapping,
				isApi: project.source.isApi,
				isFile: project.source.isFile,
				import_type: project.source.import_type,
				connection_name: project.source.connection_name,
				external_source_id: project.source.external_source_id
			}

			dispatch(updateLoadingNextStep(true))
			const fieldDefRecord =
				getState().projectWizard.currentProject.mappingInfo.mapping.currentFieldDefinitionsRecord
			// TODO: THIS SHOULD BE REMOVED ONCE BE FIXES DCP-2238 if that ever happens
			const additionalDataDef = fieldDefRecord.fieldDefinitionsRecordsMap['additionalData']
			fieldDefRecord.fieldDefinitionsRecordsMap['additionalData'] = additionalDataDef.map((fieldDef) => {
				/*TODO: REMOVE CONDITION TO FIELDTYPE OF BOOLEAN WHEN BE FIX BUG RELATIONED TO DCP-2293 */
				return {
					columnName: fieldDef?.columnName,
					fieldName: fieldDef?.fieldName,
					fieldType:
						fieldDef?.fieldType === 'DATE' ||
						fieldDef?.fieldType === 'BOOLEAN' ||
						fieldDef?.fieldType === 'INTEGER'
							? 'TEXT'
							: fieldDef?.fieldType,
					inCurrentImport: fieldDef?.inCurrentImport,
					required: fieldDef?.required
				} as MapFieldDefinition
			})
			// END TODO

			request.field_definitions_record = fieldDefRecord
			request.inbound_document_type = ''

			let apiCall
			if (project.source.connect_manage_id) {
				apiCall = apiClient.put(url, {
					...request,
					source_id: project.source.connect_manage_id
				})
			} else {
				apiCall = apiClient.post(url, request)
			}

			return apiCall
				.then(async (response: AxiosResponse<CreateSourceResponse>) => {
					dispatch(updateCurrentProjectAction({ source: { connect_manage_id: response.data.sourceId } }))

					dispatch(updateLoadingNextStep(false))
				})
				.catch((e) => {
					console.error(e)
					dispatch(updateLoadingNextStep(false))
					let errorMessage = JSON.parse(e.request.response).UIAction
					if (errorMessage) {
						const errorData: ApiErrorState = {
							errorCode: errorMessage.code,
							message: errorMessage.message
						}
						if (errorMessage.message.includes('LEDP_00002')) {
							errorData.message = i18n.t('error.message.new.source.project.archived') as string
						}
						dispatch(setApiErrorAction(errorData))
					} else {
						errorMessage = JSON.parse(e.request.response)
						const errorData: ApiErrorState = {
							errorCode: errorMessage.errorCode,
							message: errorMessage.errorMessage
						}
						dispatch(setApiErrorAction(errorData))
					}
					throw e
				})
		} else {
			return new Promise<void>((resolve, reject) => reject())
		}
	}
