import { AxiosInstance, AxiosResponse } from 'axios'

export enum ConnectionStatus {
	Failed = 'Failed',
	Completed = 'Completed',
	Ready = 'Ready',
	Unknown = 'Unknown',
	Empty = 'Empty'
}

export enum ConnectionEnvironment {
	Production = 'Production',
	Sandbox = 'Sandbox'
}

export enum CreateQueryStatus {
	Error = 'Error',
	Success = 'Success',
	Loading = 'Loading',
	Empty = 'Empty'
}

export interface ConnectionStatusResponse {
	status: ConnectionStatus
	name?: string
}

export const getConnectionStatus = async (
	apiClient: AxiosInstance,
	orgId?: string
): Promise<ConnectionStatusResponse> => {
	if (orgId) {
		const systemType = 'Salesforce'
		const url = `/pls/integration/${systemType}/organization/${orgId}/status`
		return apiClient.get(url).then((response: AxiosResponse<ConnectionStatusResponse>) => response.data)
	} else {
		return { status: ConnectionStatus.Failed }
	}
}
