import { DNBButton } from '@dnb-uux-design-system/react'
import { AxiosResponse } from 'axios'
import { FC, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { mapBE2FEDelimiter } from '../../helpers'
import UploadParser from '../../helpers/uploadParser'
import { useApi } from '../../hooks'
import { useFeatures } from '../../hooks/useEntitlements'
import { Button, Modal } from '../../local-core-ui'
import { useSource } from '../../queries/useSource'
import { changeFieldTypesToText } from '../../store/projectWizard/thunks'
import {
	DelimiterInfo,
	FieldDefinitionsRecordsMap,
	FileUploadResponse,
	MappingSchema,
	PurposeOfUse,
	StartImportRequest
} from '../../types'
import { ColumnDefinition } from '../collapsable-dropdown-collection-manager/collapsable-dropdown-collection-manager'
import { FilePreviewer } from '../file-previewer/file-previewer'
import { PillButton } from '../pill-button/pill-button'
import { IRUMLimits, RumLimitsModal } from '../rum-limits-modal/rum-limits-modal'
import { Spinner } from '../spinner/spinner'
import { UploadChooser } from '../upload-chooser/upload-chooser'
import { ModalEnrichOnly } from './modal-enrich-only'
import styles from './new-file-processor.module.scss'

interface NewFileProcessorProps {
	title: string
	projectId: string
	sourceId: string
	onClose: (existNewUpload: boolean) => void
	testId: string
	open: boolean
	purposeOfUse: PurposeOfUse | undefined
	withOptionEnrichOnly?: boolean
	isModeMixedFileActivated?: boolean
}

interface FileInfo {
	headers?: string[]
	data?: string[][]
	file?: File
	name?: string
	uploadInfo?: FileUploadResponse
	totalRows?: number
}

export const NewFileProcessor: FC<NewFileProcessorProps> = ({
	title,
	projectId,
	sourceId,
	onClose,
	testId,
	open,
	purposeOfUse,
	withOptionEnrichOnly = false,
	isModeMixedFileActivated = false
}: NewFileProcessorProps) => {
	const [fileInfo, setFileInfo] = useState<FileInfo>()
	const [uploadingFile, setUploadingFile] = useState(false)
	const [percentageUploaded, setPercentageUploaded] = useState(0)
	const [showConfirmationModal, setShowConfirmationModal] = useState(false)
	const [processing, setProcessing] = useState(false)
	const enableFileDelimiters = useFeatures(['EnableFileDelimiters'])
	const enableUIOverageAlert = useFeatures(['EnableUIOverageAlert'])
	const [delimiterInfo, setDelimiterInfo] = useState<DelimiterInfo>()
	const [confirmationScenario, setConfirmationScenario] = useState<'cancel' | 'wrong-format'>('cancel')
	const [showModalRUMFiles, setShowModalRUMFiles] = useState<boolean>(false)
	const [rumLimits, setRUMLimits] = useState<IRUMLimits>({ addOns: [], regions: [] })
	const [showOnlyEnrichCriteriaMapping, setShowOnlyEnrichCriteriaMapping] = useState<boolean>(false)
	const [mappingData, setMappingData] = useState<MappingSchema>()
	const [maxFileRows, setMaxFileRows] = useState<number>(-1)
	const [sizeLimitExceeded, setSizeLimitExceeded] = useState<boolean>(false)
	const [showModalWarningDUNS, setShowModalWarningDUNS] = useState<boolean | undefined>(undefined)
	const [isEnriching, setIsEnriching] = useState<boolean>(false)
	const [showQdunsField, setShowQdunsField] = useState<boolean>(false)
	const [wantMapDunsField, setWantMapDunsField] = useState<boolean>(false)
	const [showMapping4MixedFile, setShowMapping4MixedFile] = useState<boolean>(false)
	const [isDirectOnly4MixedFile, setIsDirectOnly4MixedFile] = useState<boolean>(true)
	const queryClient = useQueryClient()
	let hideConfirmationModal = false
	const apiClient = useApi()
	const { t } = useTranslation()
	const doesLookGood = () => {
		// Let's try to find out if the csv is really valid: Papa Parse can parse almost anything, but a file that
		// does not match header columns with row columns will always seem to be a non csv
		if (fileInfo && fileInfo.headers && fileInfo.data) {
			const headerCount = fileInfo.headers.length
			return fileInfo.data.reduce((prev: boolean, row) => prev && row.length <= headerCount, true)
		} else return false
	}

	const onConfirmFormat = () => {
		setShowConfirmationModal(false)
		uploadFile()
	}

	const getTotalRows = async (file: File, delimiter: string) => {
		let totalRows
		await UploadParser.totalRows(file, delimiter).then((total) => (totalRows = total))
		return totalRows
	}

	const onFileChange = async (selectedFile: File, firstRows: [][], delimiter: string) => {
		const totalRows = (await getTotalRows(selectedFile, delimiter)) ?? 0

		setSizeLimitExceeded(maxFileRows > -1 && totalRows > maxFileRows)

		const newFileInfo: FileInfo = {
			headers: firstRows.shift() || [],
			data: firstRows,
			file: selectedFile,
			name: selectedFile.name,
			totalRows: totalRows
		}
		setFileInfo(newFileInfo)
	}

	const startImport = (uploadInfo: FileUploadResponse, enrichOnly?: boolean) => {
		const url = '/pls/uploads/startimport'
		const createImportRequest: StartImportRequest = {
			project_id: projectId,
			source_id: sourceId,
			file_import_id: uploadInfo.file_import_id,
			enrichOnly: enrichOnly ?? false
		}
		setProcessing(true)
		apiClient
			.post(url, createImportRequest)
			.then(() => {
				queryClient.invalidateQueries(['getUploads', sourceId])
				cleanFileProcessor()
			})
			.catch((e) => {
				console.error(e)
			})
			.finally(() => {
				cleanFileProcessor()
				onClose(true)
				setProcessing(false)
				hideConfirmationModal = true
				onCloseMainModal()
			})
	}

	const uploadFile = () => {
		if (fileInfo) {
			setUploadingFile(true)
			const fileUploadURL = `/pls/fileuploads/${fileInfo.name}`
			const formData = new FormData()
			formData.append('file', fileInfo.file || '')
			const config = {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}

			apiClient
				.post(fileUploadURL, formData, config)
				.then((uploadResponse: AxiosResponse<FileUploadResponse>) => {
					setFileInfo({ ...fileInfo, uploadInfo: uploadResponse.data })
					if (withOptionEnrichOnly) {
						reviewMappingEnrichOnly(uploadResponse.data.file_import_id || '')
					} else if (isModeMixedFileActivated && wantMapDunsField && showQdunsField) {
						reviewMappingEnrichOnly(uploadResponse.data.file_import_id || '')
					} else {
						if (rumLimits.regions.length > 0 || rumLimits.addOns.length > 0) {
							startImport(uploadResponse.data)
							setShowModalRUMFiles(false)
							setUploadingFile(false)
						} else {
							setPercentageUploaded(100)
							setUploadingFile(false)
						}
					}
				})
				.catch((e) => {
					console.error(e)
				})
		}
	}

	const submitForProcessing = (enrichOnly?: boolean) => {
		if (fileInfo && fileInfo.uploadInfo) {
			startImport(fileInfo.uploadInfo, enrichOnly)
		} else {
			setFileInfo(undefined)
		}
	}

	const onCloseMainModal = () => {
		if (fileInfo) {
			setConfirmationScenario('cancel')
			hideConfirmationModal ? setShowConfirmationModal(false) : setShowConfirmationModal(true)
		} else {
			onClose(!!fileInfo)
		}
	}

	const cleanFileProcessor = () => {
		setPercentageUploaded(0)
		setUploadingFile(false)
		setFileInfo(undefined)
		setShowConfirmationModal(false)
		setMappingData(undefined)
		setShowOnlyEnrichCriteriaMapping(false)
		setShowConfirmationModal(false)
		setShowModalRUMFiles(false)
	}

	const sourceQuery = useSource(sourceId, true)

	useEffect(() => {
		if (sourceQuery.isSuccess && sourceQuery.isFetched) {
			setDelimiterInfo(sourceQuery.data.delimiterInfo)
		}
		/**
		 * We only want to uptdate this effect when isFetching flag from sourceQuery changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceQuery.isFetching])

	const enableRUMFiles = () => {
		const totalRows = fileInfo?.totalRows
		const checkRUMurl = `/pls/usage/alert/${purposeOfUse?.domain}/${purposeOfUse?.recordType}?record_count=${totalRows}`
		apiClient
			.get(checkRUMurl)
			.then((checkRUMresponse: AxiosResponse<IRUMLimits>) => {
				if (checkRUMresponse.data.addOns.length > 0 || checkRUMresponse.data.regions.length > 0) {
					setRUMLimits(checkRUMresponse.data)
					setShowModalRUMFiles(true)
				} else {
					uploadFile()
				}
			})
			.catch((error) => {
				console.error(error)
			})
	}

	const getColumns = () => {
		let columnsAutoDetected: Array<ColumnDefinition> = []
		const columns: Array<ColumnDefinition> = []
		let columnKeys = mappingData
			? mappingData.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap.additionalData
			: []
		let totalColumns: Array<ColumnDefinition> = []
		if (columnKeys.length > 0) {
			//Here columnKeys contain the possible option by dropdown
			totalColumns = columnKeys
				.map((column) => {
					const columnExist = columns.find((columnExist) => columnExist.name === column?.columnName)

					if (column.inCurrentImport && columnExist === undefined) {
						const temp = {
							name: column?.columnName,
							description: column?.columnName
						} as ColumnDefinition
						columns.push(temp)
						return temp
					}
				})
				.filter((column) => column !== undefined)
		}
		columnKeys = mappingData?.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap['dunsNumber']
		if (columnKeys !== undefined && columnKeys.length > 0 && columnKeys[0].inCurrentImport) {
			//Here columnKeys contain the value for DunsNumber that was autodetected by BE
			columnsAutoDetected = columnKeys.map((column) => {
				const columnExist = totalColumns.find((columnTotal) => columnTotal.name === column?.columnName)
				if (column.inCurrentImport && columnExist === undefined) {
					return {
						name: column?.columnName,
						description: column?.columnName
					} as ColumnDefinition
				}
			})
		}
		//columns contain the options of dropdown and the current value of dunsNumber
		return [...new Set([...totalColumns, ...columnsAutoDetected])].filter((column) => column !== undefined)
	}

	const getMappingEnrich = (fileImportId: string) => {
		const urlFileImport = `/pls/sources/mappings?entityType=${sourceQuery.data?.entityType}${
			'&sourceId=' + sourceQuery.data?.sourceId
		}${'&fileImportId=' + fileImportId}${'&delimiter=' + delimiterInfo?.detected_delimiter}`
		apiClient.get(urlFileImport).then((response: AxiosResponse<MappingSchema>) => {
			changeFieldTypesToText(response.data)
			const mappingFile: MappingSchema = response.data
			if (
				withOptionEnrichOnly &&
				!mappingFile.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap['dunsNumber'][0].inCurrentImport
			) {
				delete mappingFile.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap['dunsNumber'][0].columnName
			}
			setMappingData(mappingFile)
		})
	}

	useEffect(() => {
		if (!isEnriching) {
			setShowModalWarningDUNS(
				mappingData?.existingFieldDefinitionsMap['DUNS'] !== undefined &&
					mappingData?.existingFieldDefinitionsMap['DUNS'].columnName !==
						mappingData?.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap['dunsNumber'][0]
							.columnName
			)
		}
	}, [mappingData])

	const reviewMappingEnrichOnly = (fileImportId: string) => {
		if (withOptionEnrichOnly) {
			setShowOnlyEnrichCriteriaMapping(true)
		} else if (isModeMixedFileActivated && wantMapDunsField && showQdunsField) {
			setShowMapping4MixedFile(true)
		}
		getMappingEnrich(fileImportId)
	}

	const getMappingCurrentSource = () => {
		const urlMapping = `/pls/sources/mappings?entityType=${sourceQuery.data?.entityType}${
			'&sourceId=' + sourceQuery.data?.sourceId
		}`
		apiClient.get(urlMapping).then((response: AxiosResponse<MappingSchema>) => {
			const haveCurrentDUNS =
				response.data.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap['dunsNumber'][0].columnName !==
				undefined
			setShowQdunsField(!haveCurrentDUNS)
		})
	}

	useEffect(() => {
		const fileSizeLimitUrl = '/pls/fileuploads/fileSizeLimit'

		apiClient
			.get(fileSizeLimitUrl)
			.then((response) => {
				setMaxFileRows(response.data.max_rows)
			})
			.catch((error) => {
				console.error(error)
			})
	}, [])

	useEffect(() => {
		if (isModeMixedFileActivated && sourceQuery.data?.sourceId && !withOptionEnrichOnly) {
			getMappingCurrentSource()
		}
	}, [])

	return (
		<Modal
			open={open}
			onClose={onCloseMainModal}
			isContainer={!showConfirmationModal}
			showButtonClose={!showConfirmationModal}
			testId="NewFileProcessorModal"
		>
			{showModalRUMFiles ? (
				<RumLimitsModal
					open={showModalRUMFiles}
					addOns={rumLimits?.addOns}
					regions={rumLimits?.regions}
					processFile={() => {
						uploadFile()
						setShowModalRUMFiles(false)
						if (!withOptionEnrichOnly && !(isModeMixedFileActivated && wantMapDunsField)) {
							onClose(false)
						}
					}}
					saveNoProcessFile={() => {
						cleanFileProcessor()
						onClose(false)
					}}
					records={fileInfo?.totalRows || 0}
				/>
			) : showConfirmationModal ? (
				<div className={styles.newFileProcessorConfirmationModal}>
					{confirmationScenario === 'cancel' ? (
						<>
							<span className={styles.confirmationModalText}>{t('cancel.file.processing')}</span>
							<div className={styles.buttonsContainer}>
								<Button
									text={t('confirmation.file.processing.continue')}
									onClick={() => setShowConfirmationModal(false)}
									size="large"
									type="primary"
									testId={testId + '-continue'}
								/>
								<Button
									text={t('confirmation.file.processing.back')}
									onClick={() => {
										cleanFileProcessor()
										onClose(!!fileInfo)
									}}
									size="medium"
									type="secondary"
									testId={testId + '-back'}
								/>
							</div>
						</>
					) : (
						<>
							<p className={styles.confirmationModalText}>{t('file.previewer.are.you.sure')}</p>
							<div className={styles.buttonsContainer}>
								<Button
									text={t('file.previewer.yes.i.am')}
									onClick={onConfirmFormat}
									testId={testId + '-confirm'}
								/>
								<Button
									type="secondary"
									text={t('confirmation.modal.cancel')}
									onClick={() => setShowConfirmationModal(false)}
									testId={testId + '-cancel'}
								/>
							</div>
						</>
					)}
				</div>
			) : !fileInfo ? (
				delimiterInfo ? (
					<UploadChooser
						onFileSelect={onFileChange}
						title={title}
						testId={testId + '-uc'}
						selectedDelimiter={mapBE2FEDelimiter(delimiterInfo?.chosen_delimiter)}
					/>
				) : (
					<Spinner />
				)
			) : (showOnlyEnrichCriteriaMapping && mappingData && showModalWarningDUNS !== undefined) ||
			  (mappingData && showMapping4MixedFile) ? (
				<>
					{showModalWarningDUNS && !isEnriching ? (
						<div className={styles.warningDUNSModal}>
							<div className={styles.titleWarning}>{t('modal.warning.duns.text.title')}</div>
							<div className={styles.descriptionWarning}>
								<Trans
									i18nKey="modal.warning.duns.text.verbiage"
									tOptions={{ filename: fileInfo.uploadInfo?.display_name }}
								/>
								<div className={styles.noteWarning}>{t('modal.warning.duns.note')}</div>
							</div>

							<div className={styles.buttonsContainerWarning}>
								<DNBButton
									size={'default'}
									variant={'secondary'}
									onClick={() => {
										setShowOnlyEnrichCriteriaMapping(false)
										setUploadingFile(false)
										setProcessing(false)
										setMappingData(undefined)
										setShowModalWarningDUNS(undefined)
										setFileInfo(undefined)
									}}
								>
									{t('modal.warning.duns.button.back')}
								</DNBButton>
								<DNBButton
									size={'default'}
									onClick={() => {
										setShowModalWarningDUNS(false)
										setIsEnriching(true)
									}}
								>
									{t('modal.warning.duns.button.map')}
								</DNBButton>
							</div>
						</div>
					) : (
						<ModalEnrichOnly
							open={(showOnlyEnrichCriteriaMapping && !showModalWarningDUNS) || showMapping4MixedFile}
							onClose={() => {
								setIsEnriching(false)
								cleanFileProcessor()
								onClose(false)
							}}
							onBack={() => {
								setIsEnriching(false)
								setShowOnlyEnrichCriteriaMapping(false)
								setUploadingFile(false)
								setProcessing(false)
								setShowModalWarningDUNS(undefined)
								setShowMapping4MixedFile(false)
							}}
							columns={getColumns()}
							mappingData={mappingData}
							onChangeFunction={(updatedData: FieldDefinitionsRecordsMap) => {
								setMappingData({
									...mappingData,
									currentFieldDefinitionsRecord: {
										...mappingData.currentFieldDefinitionsRecord,
										fieldDefinitionsRecordsMap: updatedData
									}
								})
							}}
							fileImportId={fileInfo.uploadInfo?.file_import_id || ''}
							sourceData={sourceQuery.data}
							projectId={projectId}
							startImport={() => {
								submitForProcessing(showOnlyEnrichCriteriaMapping)
							}}
							testId={testId + 'modal-enrich-only'}
							isMapping4MixedFile={showMapping4MixedFile}
							onChange4MixedFile={(newValue: string | undefined) => {
								if (newValue) {
									setIsDirectOnly4MixedFile(JSON.parse(newValue))
								}
							}}
							isDirectOnly4MixedFile={isDirectOnly4MixedFile}
						/>
					)}
				</>
			) : sizeLimitExceeded ? (
				<div className={styles.recordLimitModalContent}>
					<div className={styles.textWrapper}>
						<p className={styles.title}>{t('upload.chooser.modal.recordLimit.title')}</p>

						<p className={styles.description}>
							<Trans
								i18nKey="upload.chooser.modal.recordLimit.description"
								tOptions={{ recordLimit: maxFileRows.toLocaleString() }}
							/>
						</p>
					</div>
					<div className={styles.confirmationButtonsContainer}>
						<DNBButton
							size="default"
							variant="secondary"
							onClick={() => {
								setFileInfo(undefined)
								setSizeLimitExceeded(false)
							}}
							data-testid={`${testId}-record-limit-modal-cancel`}
						>
							{t('upload.chooser.modal.recordLimit.button.cancel')}
						</DNBButton>
						<DNBButton
							size="default"
							variant="primary"
							onClick={() => setSizeLimitExceeded(false)}
							data-testid={`${testId}-record-limit-modal-continue`}
						>
							{t('upload.chooser.modal.recordLimit.button.continue')}
						</DNBButton>
					</div>
				</div>
			) : (
				<div className={styles.newFileProcessor}>
					<FilePreviewer
						title={fileInfo.name}
						titleSuffix={t('file.previewer.line.1') as string}
						headers={fileInfo.headers}
						data={fileInfo.data}
						percentageUploaded={percentageUploaded}
						uploadingFile={uploadingFile}
						onConfirm={() => {
							if (
								fileInfo.uploadInfo?.file_import_id !== undefined &&
								(withOptionEnrichOnly ||
									(isModeMixedFileActivated && showQdunsField && wantMapDunsField))
							) {
								reviewMappingEnrichOnly(fileInfo.uploadInfo.file_import_id || '')
							} else {
								if (doesLookGood() && fileInfo?.totalRows) {
									if (enableUIOverageAlert) {
										enableRUMFiles()
									} else {
										uploadFile()
									}
								} else {
									setConfirmationScenario('wrong-format')
									setShowConfirmationModal(true)
								}
							}
						}}
						onCancel={() => {
							setFileInfo(undefined)
							setWantMapDunsField(false)
						}}
						testId={testId + '-fp'}
						showFileDelimiters={enableFileDelimiters}
						detectedDelimiter={mapBE2FEDelimiter(delimiterInfo?.chosen_delimiter)}
						disableFileDelimiterChanges={true}
						isModeMixedFileActivated={isModeMixedFileActivated && showQdunsField}
						onChangeQMapDUNS={(mapDUNS: string) => {
							setWantMapDunsField(mapDUNS === 'true')
						}}
						wantMapDunsField={wantMapDunsField}
						totalRecords={fileInfo.totalRows}
						totalRecordsSuffix={t('file.previewer.line.totalRecords') as string}
					/>
					{percentageUploaded === 100 && (
						<div className={styles.processButtonContainer}>
							{processing ? (
								<div className={styles.centeredSpinner}>
									<Spinner />
								</div>
							) : (
								<PillButton
									text={t('submit.file.processing')}
									onClick={() => submitForProcessing(false)}
									testId={testId + '-submit'}
									disabled={processing}
								/>
							)}
						</div>
					)}
				</div>
			)}
		</Modal>
	)
}
