import { AxiosInstance, AxiosResponse } from 'axios'
import { ConnectionStatus } from './getC4SActiveConnection'

export enum System {
	Salesforce = 'Salesforce',
	Snowflake = 'Snowflake',
	ConnectManage = 'ConnectManage'
}

export interface Connection {
	name: string
	system: System
	created: number
	updated: number
	user: string
	status: ConnectionStatus
	displayName: string
}

export const getConnections = async (apiClient: AxiosInstance, systemType?: System): Promise<Connection[]> => {
	const url = systemType ? `/pls/integration/connection?systemType=${systemType}` : `/pls/integration/connection`

	return apiClient.get(url).then((response: AxiosResponse<Connection[]>) => response.data)
}
