import { DNBSelect, DNBSelectOption, DNBToggle } from '@dnb-uux-design-system/react'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './match-connect-manage.module.scss'

import designTokens from '@dnb-uux-design-system/design-tokens/build/index'
import { Snackbar, SnackType } from '../../../../components/snackbar/snackbar'
import { useWizardContext } from '../../../../components/wizardComposer/hooks/useWizardContext'
import { System } from '../../../../queries/api/getConnections'
import { useConnections } from '../../../../queries/useConnections'
import { RootState, TDispatch, useAppDispatch, useAppSelector } from '../../../../store'
import { updateCurrentProjectAction } from '../../../../store/projectWizard/actions'

export function MatchConnectManage(): ReactElement {
	const { t } = useTranslation()
	const [sendConnectManage, setSendConnectManage] = useState(true)
	const context = useWizardContext()

	const [selectedConnection, setSelectedConnection] = useState(
		context.wizardContext.projectWizardState.currentProject.source.connection_name ?? ''
	)
	const [inputValue, setInputValue] = useState(
		context.wizardContext.projectWizardState.currentProject.source.external_source_id ?? ''
	)

	const { TokenSizing } = designTokens

	const selectConnections = (state: RootState) => state.connection
	const connection = useAppSelector(selectConnections)
	const { data: connections, isLoading } = useConnections(true, connection.createQueryStatus, System.ConnectManage)

	const [showSnackbar, setShowSnackbar] = useState(false)

	const dispatch: TDispatch = useAppDispatch()

	useEffect(() => {
		if (connections && connections.length === 0 && !isLoading) {
			setShowSnackbar(true)
		} else {
			setShowSnackbar(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connections, isLoading])

	useEffect(() => {
		setSendConnectManage(context.wizardContext.projectWizardState.currentProject.source.enableConnectManage ?? true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const updateEnableConnectManage = (selectedConnection: string, inputValue: string) => {
		dispatch(
			updateCurrentProjectAction({
				source: {
					import_type: System.ConnectManage,
					connection_name: selectedConnection,
					external_source_id: inputValue,
					enableConnectManage: sendConnectManage
				}
			})
		)
	}

	const handleToggleChange = () => {
		setSendConnectManage(!sendConnectManage)

		dispatch(updateCurrentProjectAction({ source: { enableConnectManage: !sendConnectManage } }))
	}

	const handleSelectChange = (value: string) => {
		setSelectedConnection(value)

		updateEnableConnectManage(value, inputValue)
	}

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value
		setInputValue(inputValue)

		updateEnableConnectManage(selectedConnection, inputValue)
	}

	return (
		<>
			<div className={styles.matchConnectManageStep}>
				<h1 data-testid="matching-connect-manage-step">{t('matching.step.connect.manage')}</h1>
				<span className={styles.description}>{t('matching.step.connect.manage.description')}</span>
				<div className={styles.connectManageToggle}>
					<span className={styles.description}>{t('matching.step.connect.manage.send')}</span>
					<span className={styles.trademark}>{t('matching.step.connect.manage.tm')}</span>
					<DNBToggle onChange={handleToggleChange} checked={sendConnectManage} isLoading={false} />
				</div>
				<div className={styles.banner}>
					{showSnackbar && (
						<Snackbar
							title={t('matching.step.connect.manage.banner.title')}
							message={t('matching.step.connect.manage.banner.description') ?? ''}
							linkText={''}
							onClickLink={() => {}}
							type={SnackType.warning}
							isBanner
						/>
					)}
				</div>
				<div className={styles.connectTitleDropList}>
					<span>{t('matching.step.connect.manage.connection')}</span>
					<span className={`${styles.trademark} ${styles.redText}`}>*</span>
					<div className={styles.connectDropList}>
						<DNBSelect
							id={`connect-manage-selector`}
							size="default"
							minWidth={TokenSizing.SizingWidthModal}
							onChange={(_event: any, selection: string) => handleSelectChange(selection)}
							placeholder={t('matching.step.connect.manage.select.connection') || ''}
							disabled={!sendConnectManage}
							value={selectedConnection || ''}
						>
							<DNBSelectOption value="" />
							{connections && connections.length > 0 ? (
								connections.map((option) => (
									<DNBSelectOption
										key={option.name}
										value={option.name}
										disabled={option.status !== 'Ready'}
									>
										{option.displayName}
									</DNBSelectOption>
								))
							) : (
								<DNBSelectOption value="" disabled>
									{isLoading
										? t('Loading...')
										: t('matching.step.connect.manage.no.connection.available')}
								</DNBSelectOption>
							)}
						</DNBSelect>
						<a>{t('matching.step.connect.manage.create.new')}</a>
					</div>
				</div>

				<div className={styles.connectTitleDropList}>
					<span>{t('matching.step.connect.manage.soruceid')}</span>
					<span className={`${styles.trademark} ${styles.redText}`}>*</span>
					<div className={styles.inputWrapper}>
						<input
							id={`sourceId-input`}
							className={styles.textInput}
							type="text"
							placeholder={t('matching.step.connect.manage.select.cm.sourceid') || ''}
							onChange={handleInputChange}
							disabled={!sendConnectManage}
							value={inputValue}
						/>
					</div>
					<div className={styles.requiredDescription}>
						<span>{t('matching.step.connect.manage.sourceid.required')}</span>
						<span className={styles.trademark}>{t('matching.step.connect.manage.tm')}</span>
					</div>
				</div>
			</div>
		</>
	)
}
