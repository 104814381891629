import { FC, useEffect, useState } from 'react'
import { useFeatures, useModules } from '../../hooks/useEntitlements'
import { ConnectionStatus } from '../../queries/api/getC4SActiveConnection'
import { useSource } from '../../queries/useSource'
import { useSourceConnection } from '../../queries/useSourceConnection'
import { SourceConnection } from '../source-connection/sourceConnection'
import { DataUpdatePanel } from './components/SourceRefreshRematchOnDemand/data-update-panel'
import styles from './source-settings-on-demand.module.scss'

interface SourceSettingsProps {
	sourceId: string
	projectId: string
}

export const SourceSettingsOnDemand: FC<SourceSettingsProps> = ({ sourceId, projectId }: SourceSettingsProps) => {
	const sourceQuery = useSource(sourceId, false)
	const enableC4SAuthMgmt = useFeatures(['EnableC4SAuthMgmt'])
	const isC4S = useModules(['C4S'])
	const sourceConnectionQuery = useSourceConnection(sourceId, enableC4SAuthMgmt)
	const [isSalesforceSource, setSalesforceSource] = useState<boolean>(false)
	const [isConnected, setIsConnected] = useState<boolean>(true)
	const [connectionDisplayName, setConnectionDisplayName] = useState('')

	useEffect(() => {
		if (sourceQuery.isSuccess && sourceQuery.isFetched) {
			setSalesforceSource(sourceQuery.data?.integrationInfo?.integrationType === 'Salesforce')
		}
		/**
		 * We only want to run this effect when the flag isFetching from sourceQuery changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceQuery.isFetching])

	useEffect(() => {
		if (sourceConnectionQuery.isSuccess && sourceConnectionQuery.isFetched && enableC4SAuthMgmt) {
			setIsConnected(sourceConnectionQuery?.data?.status === ConnectionStatus.Ready)
			setConnectionDisplayName(sourceConnectionQuery?.data?.name || '')
		}
		/**
		 * We want to run effect to get the current C4S connection status related with current source.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceConnectionQuery.isFetching, enableC4SAuthMgmt])

	return isSalesforceSource ? (
		<div className={styles.sourceSettingsOnDemand}>
			{enableC4SAuthMgmt && !isConnected && <SourceConnection connectionDisplayName={connectionDisplayName} />}
			{isC4S &&
				(enableC4SAuthMgmt
					? sourceConnectionQuery.isFetched && sourceConnectionQuery?.data?.status === ConnectionStatus.Ready
					: true) && <DataUpdatePanel sourceId={sourceId} projectId={projectId} />}
		</div>
	) : (
		<></>
	)
}
