import { DynamicWizardContext } from '../../../components/wizardComposer/helpers/DynamicWizardContext'
import { WizardContext } from '../../../components/wizardComposer/helpers/WizardContext'
import {
	WizardComposerStepDefinition,
	WizardComposerSubStepStates
} from '../../../components/wizardComposer/types/WizardComposerStepDefinition'
import { removeProjectFromWizardAction, updateCurrentProjectAction } from '../../../store/projectWizard/actions'
import {
	createOrUpdateSource,
	retrieveThirdPartyIntegrationSampleCSVFileAndUpload
} from '../../../store/projectWizard/thunks'
import { EntityType } from '../../../types/sources/EntityType'
import { ChooseMatchType } from './choose-match-type/chooseMatchType'
import { CreateSource } from './create-source/createSource'
import { PreviewFile } from './preview-file'
import { SelectInput } from './select-input/selectInput'

export const WizardComposerSetupSourceStepDefinition = (
	context: WizardContext,
	dynamicContext?: DynamicWizardContext
): WizardComposerStepDefinition => {
	const isApiOnly = (): boolean =>
		context.enableApi &&
		context.projectWizardState.currentProject.source.isApi &&
		!context.projectWizardState.currentProject.source.isFile

	const isCrmOnly = (): boolean =>
		context.projectWizardState.currentProject.thirdPartyIntegration !== undefined &&
		context.projectWizardState.currentProject.fileInfo?.uploadInfo?.file_import_id !== undefined

	const getStepProgress = (): number => {
		let stepProgress = 0
		if (!context.projectWizardState.currentProject.id) return 0
		else {
			if (context.salesforcePlatform) {
				stepProgress = context.projectWizardState.currentProject.fileInfo?.uploadInfo
					? 100
					: context.projectWizardState.currentProject.fileInfo?.file
					? 66
					: context.projectWizardState.currentProject.thirdPartyIntegration?.crmSource
					? 33
					: 0
			} else {
				if (isApiOnly()) {
					stepProgress = context.projectWizardState.currentProject.source.id ? 100 : 90
				} else {
					if (context.projectWizardState.selectFileStep.fileAlreadyProcessed) {
						stepProgress = 100
					} else {
						if (context.projectWizardState.currentProject.fileInfo?.file) {
							if (context.projectWizardState.currentProject.fileInfo?.uploadInfo?.file_import_id) {
								stepProgress = 100
							} else stepProgress = 51
						}
					}
				}
			}
			return stepProgress
		}
	}

	const createSourceSubStep = {
		title: 'upload.chooser.data.heading',
		state: () =>
			context.projectWizardState.currentProject.source.name.trim() !== '' || isCrmOnly()
				? WizardComposerSubStepStates.complete
				: WizardComposerSubStepStates.pending,
		onNext: undefined,
		onPrevious: undefined,
		isNextEnabled: () =>
			context.projectWizardState.currentProject.source.name.trim() !== '' ||
			context.projectWizardState.currentProject.thirdPartyIntegration !== undefined,
		route: {
			path: '/define-source',
			component: CreateSource
		}
	}

	const previewFileSubStep = {
		title: 'file.previewer.upload.button',
		state: () =>
			context.projectWizardState.selectFileStep.fileAlreadyProcessed ||
			context.projectWizardState.currentProject.fileInfo?.uploadInfo?.file_import_id
				? WizardComposerSubStepStates.complete
				: WizardComposerSubStepStates.pending,
		onNext: undefined,
		onPrevious: undefined,
		isNextEnabled: () =>
			context.projectWizardState.selectFileStep.fileAlreadyProcessed ||
			(context.projectWizardState.currentProject.fileInfo?.uploadInfo?.file_import_id !== undefined &&
				!context.projectWizardState.loadingNextStep),
		route: { path: '/preview-data', component: PreviewFile }
	}

	const chooseMatchTypeSubStep = {
		title: 'choose.match.type',
		state: () =>
			context.projectWizardState.currentProject.source.entityType
				? WizardComposerSubStepStates.complete
				: WizardComposerSubStepStates.pending,
		onNext: undefined,
		onPrevious: undefined,
		isNextEnabled: () => context.projectWizardState.currentProject.source.entityType !== undefined,
		route: { path: '/choose-match-type', component: ChooseMatchType }
	}

	const selectInputSourceSubStep = {
		title: 'Select Input Source',
		state: () =>
			isApiOnly() ||
			isCrmOnly() ||
			context.projectWizardState.currentProject.fileInfo?.uploadInfo?.file_import_id !== undefined
				? WizardComposerSubStepStates.complete
				: WizardComposerSubStepStates.pending,
		onNext: async () => {
			//Don't call the Integrations Sample API endpoint if we already have a sourceId
			if (
				context.projectWizardState.currentProject.thirdPartyIntegration &&
				context.projectWizardState.currentProject.source.id === undefined
			)
				await context.dispatch(retrieveThirdPartyIntegrationSampleCSVFileAndUpload())

			// If we are not entitled to Contact Match and we haven't set the default yet, go ahead and set it
			if (
				!dynamicContext?.contactMatchEntitled &&
				context.projectWizardState.currentProject.source.entityType !== EntityType.ACCOUNTS
			)
				await context.dispatch(
					updateCurrentProjectAction({
						source: {
							entityType: EntityType.ACCOUNTS
						}
					})
				)
		},
		onPrevious: undefined,
		isNextEnabled: () =>
			isApiOnly() ||
			context.projectWizardState.currentProject.thirdPartyIntegration?.crmSource !== undefined ||
			context.projectWizardState.currentProject.fileInfo?.uploadInfo?.file_import_id !== undefined,
		route: { path: '/select-input', component: SelectInput }
	}

	const getSubSteps = () => {
		if (isApiOnly() || context.projectWizardState.currentProject.thirdPartyIntegration?.crmSource) {
			if (dynamicContext?.contactMatchEntitled)
				return [chooseMatchTypeSubStep, selectInputSourceSubStep, createSourceSubStep]
			else return [selectInputSourceSubStep, createSourceSubStep]
		} else {
			if (dynamicContext?.contactMatchEntitled)
				return [chooseMatchTypeSubStep, selectInputSourceSubStep, previewFileSubStep, createSourceSubStep]
			else return [selectInputSourceSubStep, previewFileSubStep, createSourceSubStep]
		}
	}

	return {
		title: 'project.wizard.step.title.select.data',
		progress: () => getStepProgress(),
		onNext: async () => {
			if (isApiOnly()) {
				await context
					.dispatch(createOrUpdateSource(false, context.enableApi, context.salesforcePlatform))
					.then(() =>
						context.queryClient.invalidateQueries([
							'getSources',
							{
								sourceId: context.projectWizardState.currentProject.source.id,
								inflateMetadata: false
							}
						])
					)
			} else return undefined
		},
		onPrevious: undefined,
		onCancel: async () => {
			const selectFileStepEmpty =
				context.projectWizardState.currentProject.source.name === '' &&
				!context.projectWizardState.currentProject.source.isFile &&
				!context.projectWizardState.currentProject.source.isApi &&
				context.projectWizardState.currentProject.source.sourceDescription === ''
			if (context.projectWizardState.currentProject.id && selectFileStepEmpty) {
				context.dispatch(removeProjectFromWizardAction(context.projectWizardState.currentProject.id))
			}
		},
		subSteps: () => getSubSteps()
	}
}
