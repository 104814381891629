import { ColorHan, ColorHanLight } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import cx from 'classnames'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useApi } from '../../hooks'
import { Tab, TabPanel, Tabs } from '../../local-core-ui'
import { getSources } from '../../queries/api/getSources'
import { useProject } from '../../queries/useProject'
import { Source } from '../../types'
import { setEmailDefaultElements } from '../../utils'
import { ProjectTeamMembers } from '../project-team-members/project-team-members'
import { SkeletonSectionData } from '../skeletons/skeleton-section-data/skeleton-section-data'
import styles from './project-overview.module.scss'
import { SourceOverview } from './source-overview'

interface IProjectOverviewProps {
	readOnly: boolean
	wizardMode?: boolean
	projectId: string
	haveSources?: boolean
	testId: string
}

export const ProjectOverview: FC<IProjectOverviewProps> = ({
	readOnly,
	wizardMode = false,
	projectId,
	haveSources = false,
	testId
}: IProjectOverviewProps) => {
	const { t } = useTranslation()
	const menuOptionsUsers = [{ label: t('edit.project.users'), action: 'editProjectUsers' }]
	const [tabSource, setTabSource] = useState<number>(0)
	const [sourceList, setSourceList] = useState<Array<Source>>([])
	const projectOverview = useProject(projectId)
	const apiClient = useApi()

	const getSourcesList = async () => {
		await getSources(apiClient, projectId).then((response) => {
			setSourceList(response)
		})
	}

	const filteredSourceList = sourceList.filter(
		(source: Source) => !source.isFile || !(source.direct_enrich === undefined && source.enableAme === undefined)
	)

	useEffect(() => {
		getSourcesList().then()
		setEmailDefaultElements(apiClient)
	}, [])

	const onMenuClick = (action: string) => {
		if (action === 'editProjectUsers') {
			window.location.href = '/steps/project-wizard/project'
		}
	}
	return (
		<div
			className={cx(
				styles.projectOverviewMetadata,
				`${
					(projectOverview.data?.sources && projectOverview.data?.sources.length > 0) || haveSources
						? styles.withSources
						: styles.withOutSources
				}`
			)}
			data-testid="project-overview-metadata"
		>
			<div className={styles.titleProject}>
				<span data-testid="project-details-title" className={styles.titleProject}>
					{projectOverview.data?.projectDisplayName}
				</span>
			</div>
			{projectOverview && projectOverview.data?.team?.TeamMembers && projectOverview.data?.purposeOfUse ? (
				<>
					{projectOverview.data?.projectDescription && (
						<div className={styles.descriptionProject}>
							<div data-testid="project-details-description" className={styles.titleSection}>
								{t('create.project.form.label.description')}
							</div>
							<div data-testid="project-description" className={styles.description}>
								{projectOverview.data?.projectDescription}
							</div>
						</div>
					)}
					<div className={styles.purposeOfUse}>
						<div data-testid="project-details-purpose-title" className={styles.titleSection}>
							{t('section.dataPurposeOfUse')}
						</div>
						<div>
							<span data-testid="project-details-domain-selected">
								<b data-testid="project-details-domain-title">{t('section.domain')}</b>
								&nbsp;
								{projectOverview.data?.purposeOfUse?.domain}
							</span>
							<br />
							<span data-testid="project-details-data-use">
								<b data-testid="project-details-data-use-title">{t('section.dataUse')}</b>
								&nbsp;
								{t(projectOverview.data?.purposeOfUse?.recordType + '')}&nbsp;{'-'}&nbsp;
								{t(projectOverview.data?.purposeOfUse?.recordType + '.helpText')}
							</span>
						</div>
					</div>
					<div className={styles.containerMembers}>
						<ProjectTeamMembers
							teamMembers={projectOverview.data?.team?.TeamMembers}
							menuOptions={menuOptionsUsers}
							readOnly={readOnly}
							onMenuClick={onMenuClick}
							rowsToDisplay={2}
							testId={testId + '-proj-team'}
						/>
					</div>
				</>
			) : (
				<SkeletonSectionData />
			)}
			<div data-testid="sources-list-container-project" className={styles.sourcesListContainer}>
				{filteredSourceList.length > 0 ? (
					<>
						<Tabs
							value={tabSource}
							onChange={(idx) => setTabSource(idx)}
							maxVisibleTabs={4}
							span={filteredSourceList.length > 4}
							colors={{ defaultColor: ColorHan, hoverColor: ColorHanLight }}
							testId={testId + '-tc-sources'}
						>
							{filteredSourceList.map((source: Source, idx) => (
								<Tab
									key={`tabSource-${idx}`}
									label={source.sourceDisplayName}
									id={`tabSource-${source.sourceDisplayName}`}
									aria-controls={source.sourceDisplayName}
								/>
							))}
						</Tabs>
						{projectOverview?.data &&
							projectOverview.data?.sources?.length > 0 &&
							filteredSourceList.map((source: Source, idx) => (
								<TabPanel
									key={'source-' + source.sourceId}
									value={tabSource}
									index={idx}
									id={source.sourceDisplayName}
									aria-labelledby={`tabSource-${source.sourceDisplayName}`}
								>
									<SourceOverview
										projectOverview={projectOverview.data}
										source={source}
										readOnly={readOnly}
										wizardMode={wizardMode}
										testId={testId + '-source-overview-' + idx}
										purposeOfUseDetails={projectOverview.data?.purposeOfUse}
									/>
								</TabPanel>
							))}
					</>
				) : projectOverview.isFetching ? (
					<>
						<SkeletonSectionData />
						<SkeletonSectionData />
						<SkeletonSectionData />
					</>
				) : (
					<div className={styles.spinnerUsers}>{t('no.sources')}</div>
				)}
			</div>
		</div>
	)
}
