import {
	ColorBluePrimary,
	ColorBluePrimaryDark,
	ColorGray,
	ColorGrayDarker
} from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { DNBButton, DNBDialog } from '@dnb-uux-design-system/react'
import { cloneDeep, isEmpty, isUndefined } from 'lodash-es'
import { ChangeEvent, ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import information from '../../../../assets/images/info.svg'
import { AdjustableProgressBar } from '../../../../components/adjustable-progress-bar/adjustable-progress-bar'
import { DataBlockSearcher } from '../../../../components/data-block-searcher/data-block-searcher'
import { ElementUIFacade } from '../../../../components/data-block-searcher/ElementUIFacade'
import {
	EnrichmentPreviewPanel,
	EnrichmentPreviewPanelElement
} from '../../../../components/enrichment-preview-panel/enrichment-preview-panel'
import { TemplateDeprecatedFieldModal } from '../../../../components/template-deprecated-field-modal/template-deprecated-field-modal'
import { TemplateSavingModal } from '../../../../components/template-saving-modal/template-saving-modal'
import { useWizardContext } from '../../../../components/wizardComposer/hooks/useWizardContext'
import { useWizardRouteMatch } from '../../../../components/wizardComposer/hooks/useWizardRouteMatch'
import {
	filterDeprecatedElements,
	filterElementsByEntitlements,
	flattenElements,
	removeBaseInfoEnrichingBlock
} from '../../../../helpers'
import { calculateUsage } from '../../../../helpers/integration/customObjectUsageCalculator'
import { removeFamily } from '../../../../helpers/removeFamily'
import { fullFamilyTreeSelected } from '../../../../helpers/validateFullFamilyTree'
import { isTradeUpEnabled } from '../../../../helpers/validateTradeUp'
import { useApi } from '../../../../hooks'
import { useFeatures, useModules, usePlatform } from '../../../../hooks/useEntitlements'
import { CheckBox, Divider, Icon, Modal, Tab, TabPanel, Tabs } from '../../../../local-core-ui'
import { getDataBlocksElements } from '../../../../queries/api/getDataBlocksElements'
import { useEnrichmentTemplateFacadeList } from '../../../../queries/useEnrichmentTemplateFacadeList'
import { useIntegrationCustomObjectUsage } from '../../../../queries/useIntegrationCustomObjectUsage'
import { useRequiredElementsForConnectManage } from '../../../../queries/useRequiredElementsForConnectManage'
import { useRequiredElementsForFamilyTree } from '../../../../queries/useRequiredElementsForFamilyTree'
import { useSource } from '../../../../queries/useSource'
import { RootState, useAppDispatch, useAppSelector } from '../../../../store'
import {
	addElementInPreview,
	removeElementInPreview,
	setSavingLibraryFlag,
	showModalConflictFamilyTreeBlocks,
	syncElementsInPreview,
	updateCurrentProjectAction,
	updateEnrichingLayoutHashes,
	updateProceedConfirmationRequired
} from '../../../../store/projectWizard/actions'
import { updateDeprecatedElements } from '../../../../store/projectWizard/actions/updateDeprecatedElements'
import { updateDeprecatedModal } from '../../../../store/projectWizard/actions/updateDeprecatedModal'
import { createOrUpdateTemplate } from '../../../../store/projectWizard/thunks'
import { EnrichingBlock } from '../../../../store/projectWizard/types'
import { Element, ElementDetail, MatchingApproach } from '../../../../types'
import { FieldUsage } from '../../../../types/enrichment-layouts/FieldUsage'
import { getDefaultElementList, getEmailDefaultElements } from '../../../../utils'
import { TradeUpModal } from '../trade-up-modal/trade-up-modal'
import { TradeUpSelector } from '../trade-up-selector/trade-up-selector'
import {
	addElementToEnrichingLayout,
	deleteTradeUpElements,
	filterLayoutOfRemovedElements,
	generateHashesOfDataBlocks,
	getSelectedElements,
	getTradeUpElements,
	updateAllEnrichingLayoutBlocks,
	updateEnrichingLayoutWithTradeUp
} from '../utils'
import { sortDataBlockList } from '../utils/sortDataBlockList'
import { DataBlockPreviewerEnrichment } from './data-block-previewer-enrichment/data-block-previewer-enrichment'
import styles from './enrichmentPreviewer.module.scss'

interface EnrichmentPreviewerProps {
	onClickBackToLibrary?: () => void
	enrichmentTitle?: string
	readonly?: boolean
}

function EnrichmentPreviewer({
	onClickBackToLibrary,
	enrichmentTitle,
	readonly
}: EnrichmentPreviewerProps): ReactElement {
	const { t } = useTranslation()
	const { wizardContext } = useWizardContext()
	const dispatch = useAppDispatch()
	const selectSession = (state: RootState) => state.session
	const session = useAppSelector(selectSession)
	const enrichmentLibraryEnabled = useFeatures(['EnrichmentLibrary'])
	const canGenerateJsonEnrichingStep = useFeatures(['EnableJsonEnrichment'])
	const EnableUpwardFamilyTreeSideFile = useFeatures(['EnableUpwardFamilyTreeSideFile'])
	const EnableFullFamilyTreeSideFile = useFeatures(['EnableFullFamilyTreeSideFile'])
	const connectManageModule = useModules(['ConnectManage'])

	const apiClient = useApi()
	const history = useHistory()
	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const isBackOrEditDataBlock =
		projectWizardState.enrichmentMappingStep.viewed || history.location.search.indexOf('sourceId') !== -1
	const hasCRMSource = projectWizardState.currentProject.thirdPartyIntegration !== undefined
	const matchType = localStorage.getItem('company_match_type')
	if (matchType) {
		localStorage.setItem('company_match_type', '')
		dispatch(
			updateCurrentProjectAction({
				source: { company_match_type: matchType }
			})
		)
	}
	const sourceId = projectWizardState?.currentProject?.source?.id || ''
	const isRecommendationMatch =
		projectWizardState.currentProject?.matchingApproach === MatchingApproach.START_SCRATCH_MR
	const defaultElementList = getDefaultElementList(
		projectWizardState.currentProject?.source?.entityType,
		projectWizardState.currentProject?.source?.enable_email_verification,
		apiClient,
		isRecommendationMatch
	)
	const sourceQuery = useSource(sourceId)
	const defaultElements: Array<EnrichingBlock> = hasCRMSource
		? [...defaultElementList.filter((block) => block.blockId !== 'entityresolution')]
		: [...defaultElementList]
	const [isAllDataBlockSearcherOpen, setIsAllDataBlockSearcherOpen] = useState<boolean>(false)
	const [blockList, setBlockList] = useState<Array<ElementDetail>>([])
	const [isWarningShowed, setIsWarningShowed] = useState<boolean>(false)
	const [isEnrichmentSavingModalOpen, setIsEnrichmentSavingModalOpen] = useState<boolean>(false)
	const [isTemplateDeprecatedFieldModalOpen, setIsTemplateDeprecatedFieldModalOpen] = useState<boolean>(false)
	const [isLayoutDeprecatedFieldModalOpen, setIsLayoutDeprecatedFieldModalOpen] = useState<boolean>(false)
	const [deprecatedElements, setDeprecatedElements] = useState<Array<Element>>([])
	const deprecatedElementsRef = useRef<Array<Element>>([])
	const replacingElementsRef = useRef<Array<Element>>([])
	const [replacingElements, setReplacingElements] = useState<Array<Element>>([])
	const [evaluatedElement, setEvaluatedElement] = useState<number>(0)
	const [evaluatedElementLayout, setEvaluatedElementLayout] = useState<number>(0)
	const [templateName, setTemplateName] = useState('')
	const [saveTemplate, setSaveTemplate] = useState<boolean>(false)
	const [tabBlocks, setTabBlocks] = useState<number>(0)
	const [showProceedButton, setShowProceedButton] = useState<boolean>(false)
	const [showRemovalWarningModal, setShowRemovalWarningModal] = useState<boolean>(false)
	const [initialEnrichingLayout, setInitialEnrichingLayout] = useState<Array<EnrichingBlock>>()
	const isC4SBeta = useFeatures(['C4SBeta'])
	const [fieldUsage, setFieldUsage] = useState<FieldUsage>({} as FieldUsage)
	const [isValidIntegrationEnrichmentLayout, setIsValidIntegrationEnrichmentLayout] = useState<boolean>(true)
	const [isEnabledJsonOutput, setIsEnabledJsonOutput] = useState<boolean>(
		(readonly === true || readonly === undefined) && projectWizardState.currentProject.currentTemplate
			? projectWizardState.currentProject.currentTemplate.generateJson
			: projectWizardState.currentProject.generateJson
	)
	const [selectedDataTypeEnrichment, setSelectedDataTypeEnrichment] = useState('selected')
	const [isUpdateButtonEnabled, setIsUpdateButtonEnabled] = useState<boolean>(false)
	const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState<boolean>(false)
	const [dataBlockChange, setDataBlockChange] = useState(false)
	const [flattenTradeUp, setFlattenTradeUp] = useState<Array<ElementUIFacade>>([])
	const isTradeUpEnabled2Template = projectWizardState.currentProject.currentTemplate
		? projectWizardState.currentProject.currentTemplate.tradeUp !== undefined
		: projectWizardState.currentProject.tradeUp
	const [enableTradeUp, setEnableTradeUp] = useState<boolean>(projectWizardState.currentProject.tradeUp || false)
	const thirdPartyIntegration = projectWizardState.currentProject.thirdPartyIntegration
	const salesforcePlatform = usePlatform('salesforce')
	const queryClient = useQueryClient()
	const enrichmentTemplateFacadeList = useEnrichmentTemplateFacadeList(
		projectWizardState.currentProject.purposeOfUse,
		projectWizardState.currentProject.source.entityType
	)
	const { next, previous } = useWizardRouteMatch()
	const isCRMSource = thirdPartyIntegration !== undefined
	const [proceededTradeUp, setProceededTradeUp] = useState(false)
	const [initialLoad, setInitialLoad] = useState(true)
	const [showConflictModal, setShowConflictModal] = useState(
		projectWizardState.enrichmentPreviewSubStep.showConflictFamilyTreeModal
	)

	const requiredFamilyTreeQuery = useRequiredElementsForFamilyTree(projectWizardState.currentProject.purposeOfUse)
	const requiredConnectManageQuery = useRequiredElementsForConnectManage(
		projectWizardState.currentProject.purposeOfUse,
		connectManageModule && projectWizardState.currentProject.source.isFile
	)

	const showTradeUp = isTradeUpEnabled(
		useFeatures(['EnableTradeUp']),
		useFeatures(['EnableTradeUpC4S']),
		sourceQuery.data?.integrationInfo?.integrationType,
		projectWizardState?.currentProject?.source?.entityType,
		projectWizardState?.currentProject?.versionSF
	)

	const [selectedTradeUpEntity, setSelectedTradeUpEntity] = useState(
		!!projectWizardState.currentProject.currentTemplate?.tradeUp
			? projectWizardState.currentProject.currentTemplate.tradeUp
			: 'hq'
	)

	//Calculate which Family Tree fields are selected in the layout
	const familyTreeElements = useMemo<Array<EnrichmentPreviewPanelElement>>(() => {
		if (requiredFamilyTreeQuery.isSuccess) {
			const flattenedLayoutElements = flattenElements(projectWizardState.currentProject.enrichingLayout)
			const removedElements = projectWizardState.enrichmentPreviewSubStep.removedElements

			//Iterate through the Family Tree elements and mark which ones are selected in the layout
			return requiredFamilyTreeQuery.data.entitled.map((entitledElement) => {
				return {
					...entitledElement,
					isChecked:
						flattenedLayoutElements.find(
							(selectedElement) => selectedElement.elementId === entitledElement.elementId
						) &&
						!removedElements.find(
							(removedElement) => removedElement.elementId === entitledElement.elementId
						)
				} as EnrichmentPreviewPanelElement
			})
		}
		return []
		/**
		 * We only want to run this effect when the flag isFetching from requiredFamilyTreeQuery or enrichingLayout or
		 * removedElements from the projectWizardState changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		requiredFamilyTreeQuery.isFetching,
		projectWizardState.currentProject.enrichingLayout,
		projectWizardState.enrichmentPreviewSubStep?.removedElements
	])

	//Calculate which Connect Manage required fields are selected in the layout
	const connectManageElements = useMemo<Array<EnrichmentPreviewPanelElement>>(() => {
		if (requiredConnectManageQuery.isSuccess) {
			const flattenedLayoutElements = flattenElements(projectWizardState.currentProject.enrichingLayout)
			const removedElements = projectWizardState.enrichmentPreviewSubStep.removedElements

			//Iterate through the Connect Manage elements and mark which ones are selected in the layout
			const elements = requiredConnectManageQuery.data.entitled.map((entitledElement) => {
				return {
					...entitledElement,
					isChecked:
						flattenedLayoutElements.find(
							(selectedElement) => selectedElement.elementId === entitledElement.elementId
						) &&
						!removedElements.find(
							(removedElement) => removedElement.elementId === entitledElement.elementId
						)
				} as EnrichmentPreviewPanelElement
			})
			return elements.filter((element) => !element.isChecked)
		}
		return []
		/**
		 * We only want to run this effect when the flag isFetching from requiredConnectManageQuery or enrichingLayout or
		 * removedElements from the projectWizardState changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		requiredConnectManageQuery.isFetching,
		projectWizardState.currentProject.enrichingLayout,
		projectWizardState.enrichmentPreviewSubStep?.removedElements
	])

	const getBlockList = async () => {
		// const isRecommendationMatch = projectWizardState.currentProject?.source?.company_match_type === "MatchRecommendation"
		const selectedElements = getSelectedElements(
			projectWizardState.currentProject.enrichingLayout,
			hasCRMSource,
			projectWizardState.currentProject?.source?.entityType,
			projectWizardState.currentProject?.source?.enable_email_verification,
			apiClient,
			isRecommendationMatch
		)
		const dataBlockList = onClickBackToLibrary
			? await getDataBlocksElements(apiClient)
			: await filterElementsByEntitlements(
					apiClient,
					dispatch,
					selectedElements,
					projectWizardState.currentProject.purposeOfUse,
					undefined,
					projectWizardState.currentProject.source.entityType
			  )
		if (showTradeUp) {
			setFlattenTradeUp(getTradeUpElements(dataBlockList))
		} else if (isCRMSource) {
			deleteTradeUpElements(dataBlockList)
		}
		if (!EnableUpwardFamilyTreeSideFile) {
			removeFamily(dataBlockList, 'hierarchyconnections', 'upward')
		}
		if (!EnableFullFamilyTreeSideFile) {
			removeFamily(dataBlockList, 'hierarchyconnections', 'fullfamily')
		}
		setBlockList(filterDeprecatedElements(dataBlockList, selectedElements, wizardContext.enableEmailVerification))
	}

	const areTemplateAndLayoutEquals = () => {
		let areEqual = false
		if (enrichmentTemplateFacadeList) {
			let templateDataBlocks: Array<EnrichingBlock> = []
			const enrichingLayout = [...projectWizardState.currentProject.enrichingLayout]
			const currentEnrichingTemplate = enrichmentTemplateFacadeList.data?.find(
				(enrichingTemplate) =>
					enrichingTemplate.templateId === projectWizardState.currentProject.currentTemplate?.templateId
			)

			if (currentEnrichingTemplate) {
				templateDataBlocks = removeBaseInfoEnrichingBlock(
					currentEnrichingTemplate.dataBlocks,
					showTradeUp && isTradeUpEnabled2Template !== undefined
				)
			}
			const { includeBranches } = projectWizardState.currentProject
			const templateIncludeBranches = projectWizardState.currentProject.currentTemplate?.includeBranches
			areEqual =
				areLayoutEquals(enrichingLayout, templateDataBlocks) && templateIncludeBranches == includeBranches
		}
		return areEqual
	}

	const selectedCount = () => {
		return projectWizardState.currentProject.enrichingLayout.reduce(
			(accumulator: number, currentBlock) => accumulator + currentBlock.elements.length,
			-projectWizardState.enrichmentPreviewSubStep.removedElements?.length
		)
	}

	const getDataBlocks = () => {
		const generatedComponent: Array<JSX.Element> = []
		const enrichingLayout = projectWizardState.currentProject.enrichingLayout.filter(filterBlockListForSalesforce)
		enrichingLayout.forEach((block) => {
			const defaultExist = defaultElements.find((defaultElement) => defaultElement.blockId === block.blockId)
			if (block.elements && block.elements.length) {
				generatedComponent.push(
					<DataBlockPreviewerEnrichment
						selectedElements={block.elements}
						title={t(block.blockId) as string}
						key={'block-tab-' + block.blockId}
						defaultElements={defaultExist ? defaultExist.elements : undefined}
						readOnly={readonly}
						blockId={block.blockId}
					/>
				)
			}
		})
		return generatedComponent
	}

	const validateAndSave = (templateName: string) => {
		setTemplateName(templateName)
		validateDeprecated()
		setSaveTemplate(true)
		if (deprecatedElements.length > 0) {
			setIsTemplateDeprecatedFieldModalOpen(true)
		} else {
			saveEnrichmentTemplate(templateName)
		}
	}

	const validateAndUpdate = () => {
		validateDeprecated()
		setSaveTemplate(false)
		if (deprecatedElements.length > 0) {
			setIsTemplateDeprecatedFieldModalOpen(true)
		} else {
			updateEnrichmentTemplate()
		}
	}

	const saveEnrichmentTemplate = async (templateName: string) => {
		dispatch(syncElementsInPreview())
		dispatch(setSavingLibraryFlag(true))
		dispatch(createOrUpdateTemplate(showTradeUp, isEnabledJsonOutput, templateName))
		setIsEnrichmentSavingModalOpen(false)
		queryClient.invalidateQueries([
			'getEnrichmentTemplateFacadeList',
			projectWizardState.currentProject.purposeOfUse
		])
	}

	const updateEnrichmentTemplate = () => {
		const currentTemplate = projectWizardState.currentProject.currentTemplate
		dispatch(syncElementsInPreview())
		dispatch(setSavingLibraryFlag(true))
		dispatch(
			createOrUpdateTemplate(
				showTradeUp,
				isEnabledJsonOutput,
				currentTemplate?.templateName || '',
				currentTemplate?.templateId
			)
		)
		queryClient.invalidateQueries([
			'getEnrichmentTemplateFacadeList',
			projectWizardState.currentProject.purposeOfUse
		])
		setIsEnrichmentSavingModalOpen(false)
		setIsUpdateButtonEnabled(false)
		setIsSaveButtonEnabled(false)
	}
	const customObjectUsageQuery = useIntegrationCustomObjectUsage(
		projectWizardState.currentProject.source.id || '',
		!!projectWizardState.currentProject.thirdPartyIntegration //This should be a boolean, the query will only run if this is true
	)

	const userOwnsTemplate = () => {
		return projectWizardState.currentProject.currentTemplate?.createdBy?.Email === session.user?.EmailAddress
	}

	const restructureToArrayOfElements = (enrichingBlocks: Array<EnrichingBlock>): Element[][] => {
		return enrichingBlocks.map((enrichingBlock) => {
			return enrichingBlock.elements.map((element) => {
				return element
			})
		})
	}

	const areLayoutEquals = (initialLayout: Array<EnrichingBlock>, currentLayout: Array<EnrichingBlock>): boolean => {
		const restructuredInitialLayout = restructureToArrayOfElements(initialLayout)
		const filteredCurrentLayout = filterLayoutOfRemovedElements(
			currentLayout,
			projectWizardState.enrichmentPreviewSubStep.removedElements
		)
		const initialEnrichingLayoutHash = generateHashesOfDataBlocks(restructuredInitialLayout)
		const currentLayoutHash = generateHashesOfDataBlocks(filteredCurrentLayout)
		return initialEnrichingLayoutHash === currentLayoutHash
	}

	const onModalClose = () => {
		dispatch(updateProceedConfirmationRequired(false))
		setShowRemovalWarningModal(false)
		setShowProceedButton(false)
		setIsWarningShowed(true)
	}

	const onModalProceed = () => {
		setShowRemovalWarningModal(false)
		setIsWarningShowed(true)
		const filteredCurrentLayout = filterLayoutOfRemovedElements(
			projectWizardState.currentProject.enrichingLayout,
			projectWizardState.enrichmentPreviewSubStep.removedElements
		)
		dispatch(updateEnrichingLayoutHashes(generateHashesOfDataBlocks(filteredCurrentLayout)))
	}

	if (showProceedButton && isCRMSource && isC4SBeta) {
		onModalProceed()
		setShowProceedButton(false)
	}

	useEffect(() => {
		getBlockList()

		if (initialEnrichingLayout) {
			setShowRemovalWarningModal(
				!areLayoutEquals(initialEnrichingLayout, projectWizardState.currentProject.enrichingLayout)
			)
		}

		/**
		 * We only want to run this effect once, which is why we are leaving the dependency array.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		validateDeprecated()
		/**
		 * We only want to run this effect when the enrichingLayout in the current project changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectWizardState.currentProject.enrichingLayout])

	useEffect(() => {
		setIsLayoutDeprecatedFieldModalOpen(deprecatedElementsRef.current.length > 0)
	}, [])

	useEffect(() => {
		if (projectWizardState.enrichmentPreviewSubStep.deprecatedModal && deprecatedElementsRef.current.length > 0) {
			setIsLayoutDeprecatedFieldModalOpen(true)
		}
		/**
		 * We only want to run this effect when have deprecated elements
		 */
	}, [projectWizardState.enrichmentPreviewSubStep.deprecatedModal])

	useEffect(() => {
		setShowRemovalWarningModal(projectWizardState.enrichmentPreviewSubStep.proceedConfirmationRequired)
		setShowProceedButton(projectWizardState.enrichmentPreviewSubStep.proceedConfirmationRequired)
	}, [projectWizardState.enrichmentPreviewSubStep.proceedConfirmationRequired])

	useEffect(() => {
		//Should only be used on CRM Sources
		if (!!projectWizardState.currentProject.thirdPartyIntegration && customObjectUsageQuery.isSuccess) {
			const usage = calculateUsage(
				customObjectUsageQuery.data,
				projectWizardState.currentProject.enrichingLayout,
				undefined,
				undefined,
				projectWizardState.enrichmentPreviewSubStep.removedElements
			)
			setFieldUsage(usage)
			const canProceed = usage.newFields + usage.existingFields <= usage.fieldLimit
			setIsValidIntegrationEnrichmentLayout(canProceed)
			if (canProceed !== projectWizardState.currentProject.isValidIntegrationEnrichmentLayout) {
				dispatch(
					updateCurrentProjectAction({
						isValidIntegrationEnrichmentLayout: canProceed
					})
				)
			}
		}

		if (EnableFullFamilyTreeSideFile) {
			const isFullFamilySelected = fullFamilyTreeSelected(projectWizardState?.currentProject?.enrichingLayout)
			const enableFullFamilyTree = projectWizardState.currentProject.includeBranches ?? true
			const includeBranches = isFullFamilySelected ? enableFullFamilyTree : null
			if (includeBranches !== projectWizardState.currentProject.includeBranches) {
				dispatch(updateCurrentProjectAction({ includeBranches: includeBranches ?? null }))
			}
		}
		/**
		 * We only want to run this effect when the enrichingLayout or the removedElements in the
		 * current project or the flag isFetching from customObjectUsageQuery changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		customObjectUsageQuery.isFetching,
		projectWizardState.currentProject.enrichingLayout,
		projectWizardState.enrichmentPreviewSubStep.removedElements
	])

	useEffect(() => {
		const lastTab = projectWizardState.currentProject.enrichingLayout.length
		const removedElements = projectWizardState.enrichmentPreviewSubStep.removedElements
		const lastTabRemoved = tabBlocks > lastTab
		const changeEntityTradeUp = selectedTradeUpEntity !== projectWizardState.currentProject.entityTradeUp
		const condition2ShowButtonsUpdateAndSave =
			removedElements.length > 0 || dataBlockChange || !areTemplateAndLayoutEquals() || changeEntityTradeUp
		if (!projectWizardState.enrichmentPreviewSubStep.isUpdatingOrSaving) {
			setIsUpdateButtonEnabled(condition2ShowButtonsUpdateAndSave)
			setIsSaveButtonEnabled(condition2ShowButtonsUpdateAndSave)
		}
		if (dataBlockChange) setDataBlockChange(false)
		if (lastTabRemoved) {
			setTabBlocks(lastTab)
		}
		if (projectWizardState.currentProject.enrichingLayout.length > 0 && initialEnrichingLayout === undefined) {
			setInitialEnrichingLayout(cloneDeep(projectWizardState.currentProject.enrichingLayout))
		}
		if (initialEnrichingLayout && !projectWizardState.loadingNextStep) {
			const areEqual = areLayoutEquals(initialEnrichingLayout, projectWizardState.currentProject.enrichingLayout)
			updateInitialLayoutHashes()
			setShowProceedButton(false)
			if (!isWarningShowed) {
				setShowRemovalWarningModal(!areEqual)
			}
		}
		/**
		 * We only want to run this effect when the lengths of enrichingLayout or removedElements in the projectWizardState changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		projectWizardState.currentProject.enrichingLayout.length,
		projectWizardState.enrichmentPreviewSubStep.removedElements.length
	])

	useEffect(() => {
		if (projectWizardState.enrichmentPreviewSubStep.proceedConfirmationRequired) {
			next().then()
		}
		/**
		 * We only want to run this effect when initialEnrichingLayoutHashes from enrichmentPreviewSubStep in projectWizardState changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectWizardState.enrichmentPreviewSubStep.initialEnrichingLayoutHashes])

	useEffect(() => {
		/**
		 * We only want to run this effect when evaluatedElement state changes.
		 */
		if (deprecatedElements.length > 0 && evaluatedElement !== 0) {
			setIsTemplateDeprecatedFieldModalOpen(true)
		}
		/**
		 * We only want to run this effect when evaluatedElement state changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [evaluatedElement])

	useEffect(() => {
		if (deprecatedElementsRef.current.length > 0) {
			setIsLayoutDeprecatedFieldModalOpen(true)
		}
	}, [evaluatedElementLayout])

	const updateInitialLayoutHashes = () => {
		if (initialEnrichingLayout) {
			const restructuredInitialLayout = restructureToArrayOfElements(initialEnrichingLayout)
			dispatch(updateEnrichingLayoutHashes(generateHashesOfDataBlocks(restructuredInitialLayout)))
		}
	}

	const onJsonOutputChange = () => {
		const newValueJsonOutput = !isEnabledJsonOutput
		dispatch(updateCurrentProjectAction({ generateJson: newValueJsonOutput }))
		setIsEnabledJsonOutput(newValueJsonOutput)
		if (projectWizardState.currentProject.currentTemplate) {
			const generateJsonChange =
				projectWizardState.currentProject.currentTemplate.generateJson !== newValueJsonOutput
			setIsUpdateButtonEnabled(generateJsonChange)
			setIsSaveButtonEnabled(generateJsonChange)
		}
	}

	const filterBlockListForSalesforce = (element: EnrichingBlock) => {
		const elementName = element.blockId
		return (hasCRMSource || salesforcePlatform) && !projectWizardState.currentProject.source.isFile
			? elementName !== 'entityresolution'
			: true
	}

	const onAddElementFromPanel = (element: ElementUIFacade) => {
		const newEnrichingLayout = [...projectWizardState.currentProject.enrichingLayout]
		addElementToEnrichingLayout(dispatch, newEnrichingLayout, element, element.blockId)
		dispatch(addElementInPreview(element))
	}

	const onRemoveElementFromPanel = (element: Element) => {
		dispatch(removeElementInPreview(element))
	}

	const onUseDeprecated = (deprecated: string) => {
		const deprecatedElement = deprecatedElements.find((element) => deprecated === element.displayName)
		replacingElements.map((element: Element) => {
			if (!isUndefined(deprecatedElement) && deprecatedElement.replacements?.includes(element.elementId)) {
				onRemoveElementFromPanel(element)
			}
		})
	}

	const onUseSuggested = (suggested: string) => {
		const element = deprecatedElements.find((element) => suggested === element.displayName)
		if (!isUndefined(element)) {
			onRemoveElementFromPanel(element)
		}
	}

	const onCloseTemplateDeprecatedFieldModal = () => {
		setEvaluatedElement(0)
		deprecatedElements.map((element: Element) => {
			onAddElementFromPanel(element)
		})
		setIsEnrichmentSavingModalOpen(false)
		setIsTemplateDeprecatedFieldModalOpen(false)
	}

	const nextDeprecatedElement = () => {
		setIsEnrichmentSavingModalOpen(false)
		setIsTemplateDeprecatedFieldModalOpen(false)
		if (evaluatedElement < deprecatedElements.length - 1) {
			setEvaluatedElement(evaluatedElement + 1)
		} else if (saveTemplate) {
			saveEnrichmentTemplate(templateName)
		} else {
			updateEnrichmentTemplate()
		}
	}

	const onUseDeprecatedLayout = (deprecated: string) => {
		const deprecatedElement = deprecatedElementsRef.current.find((element) => deprecated === element.displayName)
		replacingElementsRef.current?.forEach((element: Element) => {
			if (!isUndefined(deprecatedElement) && deprecatedElement.replacements?.includes(element.elementId)) {
				onRemoveElementFromPanel(element)
				dispatch(syncElementsInPreview())
			}
		})
	}

	const onUseSuggestedLayout = (suggested: string) => {
		const element = deprecatedElementsRef.current.find((element) => suggested === element.displayName)
		if (!isUndefined(element)) {
			onRemoveElementFromPanel(element)
			dispatch(syncElementsInPreview())
		}
	}

	const onCloseLayoutDeprecatedFieldModal = () => {
		dispatch(syncElementsInPreview())
		dispatch(updateDeprecatedModal(false))
		setEvaluatedElementLayout(0)
		validateDeprecated()
		deprecatedElementsRef.current.forEach((element: Element) => {
			onAddElementFromPanel(element)
		})
		setIsLayoutDeprecatedFieldModalOpen(false)
		previous().then()
	}

	const nextDeprecatedElementLayout = () => {
		setIsLayoutDeprecatedFieldModalOpen(false)
		if (evaluatedElementLayout < deprecatedElementsRef.current.length - 1) {
			setEvaluatedElementLayout(evaluatedElementLayout + 1)
		}
	}

	const validateDeprecated = () => {
		const enrichingLayout = projectWizardState.currentProject.enrichingLayout

		let deprecates: Element[] = []
		enrichingLayout.forEach((block) => {
			block.elements.map((element: Element) => {
				if (element.deprecated && !isUndefined(element.replacements) && element.replacements?.length > 0) {
					deprecates = deprecates.concat(element)
				}
			})
		})
		let deprecatesWithReplacements: Element[] = []
		deprecates.forEach((deprecatedElement) => {
			if (!isUndefined(deprecatedElement.replacements) && deprecatedElement.replacements?.length > 0) {
				enrichingLayout.forEach((block) => {
					block.elements.map((element: Element) => {
						if (deprecatedElement.replacements?.includes(element.elementId)) {
							deprecatesWithReplacements = deprecatesWithReplacements.concat(deprecatedElement)
						}
					})
				})
			}
		})
		deprecatesWithReplacements = deprecatesWithReplacements.filter(
			(item, index) => deprecatesWithReplacements.indexOf(item) === index
		)

		dispatch(updateDeprecatedElements(deprecatesWithReplacements))
		deprecatedElementsRef.current = deprecatesWithReplacements
		setDeprecatedElements(deprecatesWithReplacements)

		let replacements: Element[] = []
		deprecatesWithReplacements.forEach((deprecatedElement) => {
			if (!isUndefined(deprecatedElement.replacements) && deprecatedElement.replacements?.length > 0) {
				enrichingLayout.forEach((block) => {
					block.elements.map((element: Element) => {
						if (deprecatedElement.replacements?.includes(element.elementId)) {
							replacements = replacements.concat(element)
						}
					})
				})
			}
		})
		replacingElementsRef.current = replacements
		setReplacingElements(replacements)
	}

	const onChangeTradeUpEntity = (event: ChangeEvent<HTMLInputElement>) => {
		const entity = event.target.value as 'hq' | 'domhq'
		setSelectedTradeUpEntity(entity)
		dispatch(updateCurrentProjectAction({ entityTradeUp: entity }))
		if (projectWizardState.currentProject.currentTemplate) {
			const entityIsChanging = entityChange(entity)

			setIsUpdateButtonEnabled(entityIsChanging)
			setIsSaveButtonEnabled(entityIsChanging)
		}
	}

	const tradeUpOnChange = () => {
		const newStateSwitch = !projectWizardState.currentProject.tradeUp
		!newStateSwitch && setProceededTradeUp(false)
		setInitialLoad(false)
		setEnableTradeUp(newStateSwitch)
		dispatch(updateCurrentProjectAction({ tradeUp: newStateSwitch }))

		if (projectWizardState.currentProject.currentTemplate) {
			const templateTradeUp = projectWizardState.currentProject.currentTemplate.tradeUp
			const tradeUpChange = isEmpty(templateTradeUp) === !projectWizardState.currentProject.tradeUp
			let entityIsChanging = false

			if (!projectWizardState.currentProject.tradeUp && !tradeUpChange) {
				entityIsChanging = entityChange(projectWizardState.currentProject.entityTradeUp || '')
			}

			setIsUpdateButtonEnabled(tradeUpChange || entityIsChanging)
			setIsSaveButtonEnabled(tradeUpChange || entityIsChanging)
		}
	}

	const showTradeUpModel = () => {
		return enableTradeUp && !proceededTradeUp && !initialLoad
	}

	const onTradeUpModalProceed = () => {
		setProceededTradeUp(true)
	}

	const onTradeUpModalTurnOff = () => {
		setProceededTradeUp(false)
		tradeUpOnChange()
	}

	const entityChange = (entity: string): boolean =>
		projectWizardState.currentProject?.currentTemplate?.tradeUp !== entity

	useEffect(() => {
		if (flattenTradeUp.length && showTradeUp) {
			const newEnrichingLayout = [...projectWizardState.currentProject.enrichingLayout]
			updateEnrichingLayoutWithTradeUp(dispatch, flattenTradeUp, newEnrichingLayout, enableTradeUp)
		}
	}, [enableTradeUp])

	useEffect(() => {
		if (
			projectWizardState.currentProject.source.enable_email_verification &&
			!defaultElements.find((ele) => ele.blockId === 'emailverification')
		) {
			const EVBlocksParsed = getEmailDefaultElements(apiClient)
			if (EVBlocksParsed && EVBlocksParsed !== null) {
				defaultElements.push(EVBlocksParsed)
			}
		}
		if (
			defaultElements.find((ele) => ele.blockId === 'emailverification') &&
			(projectWizardState.currentProject.source.enable_email_verification === false ||
				projectWizardState.currentProject.source.enable_email_verification === undefined)
		) {
			const indexNumber = defaultElements.findIndex((ele) => ele.blockId === 'emailverification')
			defaultElements.splice(indexNumber, 1)
		}
	}, [projectWizardState.currentProject.source.enable_email_verification])

	useEffect(() => {
		setShowConflictModal(projectWizardState.enrichmentPreviewSubStep.showConflictFamilyTreeModal)
	}, [projectWizardState.enrichmentPreviewSubStep.showConflictFamilyTreeModal])

	return (
		<div className={styles.enrichmentPreviewer} data-testid="enrichment-preview-container">
			<TemplateSavingModal
				isOpen={isEnrichmentSavingModalOpen}
				onClose={() => setIsEnrichmentSavingModalOpen(false)}
				templateExist={!!projectWizardState.currentProject.currentTemplate?.templateName && userOwnsTemplate()}
				onSave={validateAndSave}
				onUpdate={validateAndUpdate}
				testId={'temporal-enrichment-saving-modal'}
				templateName={
					projectWizardState.currentProject.currentTemplate?.templateName
						? projectWizardState.currentProject.currentTemplate?.templateName
						: ''
				}
				title={t('enriching.template.modal.title')}
				inputLabel={t('enriching.template.modal.input.label')}
				inputHint={t('enriching.template.modal.input.hint')}
				btnText={t('enriching.template.modal.save')}
				updateDescription={t('enriching.template.modal.update.description')}
			/>
			{(readonly === false || readonly === undefined) && (
				<TemplateDeprecatedFieldModal
					testId={'template-deprecated-field-modal'}
					isOpen={isTemplateDeprecatedFieldModalOpen}
					onClose={onCloseTemplateDeprecatedFieldModal}
					onUseDeprecated={onUseDeprecated}
					onUseSuggested={onUseSuggested}
					onNextElement={nextDeprecatedElement}
					deprecatedElements={deprecatedElements}
					replacingElements={replacingElements}
					evaluatedIndex={evaluatedElement}
				/>
			)}
			{(readonly === false || readonly === undefined) && (
				<TemplateDeprecatedFieldModal
					testId={'layout-deprecated-field-modal'}
					isOpen={isLayoutDeprecatedFieldModalOpen}
					onClose={onCloseLayoutDeprecatedFieldModal}
					onUseDeprecated={onUseDeprecatedLayout}
					onUseSuggested={onUseSuggestedLayout}
					onNextElement={nextDeprecatedElementLayout}
					deprecatedElements={deprecatedElementsRef.current}
					replacingElements={replacingElementsRef.current}
				/>
			)}
			{showTradeUp && isCRMSource && (
				<TradeUpModal
					showTradeUp={showTradeUpModel()}
					onModalProceed={onTradeUpModalProceed}
					onModalTurnOff={onTradeUpModalTurnOff}
				/>
			)}
			{isCRMSource && isC4SBeta && isBackOrEditDataBlock && (
				<Modal
					open={(!readonly && showRemovalWarningModal) || showProceedButton}
					showButtonClose={false}
					testId="EditEnrichingModal"
				>
					<p className={styles.modalContent}>{t('enriching.modal.edit.warning.content')}</p>
					<div className={styles.modalButtonsContainer}>
						<DNBButton
							size="small"
							variant="secondary"
							onClick={() => onModalClose()}
							data-testid={'close-modal-button'}
						>
							{t('enriching.modal.button.closeAnd.edit')}
						</DNBButton>
						{showProceedButton && (
							<DNBButton
								size="small"
								variant="secondary"
								onClick={() => onModalProceed()}
								data-testid={'proceed-modal-button'}
							>
								{t('enriching.modal.button.proceed')}
							</DNBButton>
						)}
					</div>
				</Modal>
			)}
			<DNBDialog
				open={showConflictModal}
				onClose={() => {
					/*Function required for the component*/
				}}
			>
				<div className={styles.bodyConflictFamilyTreeModal}>
					<h1>{t('modal.warning.title.conflict.family.tree.blocks')}</h1>
					<div className={styles.conflictDescription}>
						<Trans i18nKey={'modal.warning.first.paragraph.conflict.family.tree.blocks'} />
						<br />
						<br />
						{t('modal.warning.second.paragraph.conflict.family.tree.blocks')}
					</div>
					<Divider color={ColorGray} />
					<div className={styles.containerButtonClose}>
						<DNBButton onClick={() => dispatch(showModalConflictFamilyTreeBlocks(false))}>
							{t('modal.warning.button.close.conflict.family.tree.blocks')}
						</DNBButton>
					</div>
				</div>
			</DNBDialog>
			<DataBlockSearcher
				title={t('enriching.step.title.previewer')}
				dataBlockList={blockList}
				selectedElements={projectWizardState.currentProject.enrichingLayout}
				defaultElements={defaultElements}
				open={isAllDataBlockSearcherOpen}
				onClose={() => setIsAllDataBlockSearcherOpen(false)}
				onConfirm={(selectedElements) => {
					updateAllEnrichingLayoutBlocks(dispatch, selectedElements)
					setIsAllDataBlockSearcherOpen(false)
					setDataBlockChange(true)
				}}
				testId={'datablock-searcher-enrichment'}
				expandedSearch={true}
				showBlockInformation={true}
				showSelectedCount={true}
				activeTradeUp={showTradeUp}
				selectedDataTypeEnrichment={deprecatedElementsRef.current.length > 0 ? selectedDataTypeEnrichment : ''}
			/>
			{onClickBackToLibrary ? (
				<div
					data-testid="back-to-library"
					className={styles.backToLibraryContainer}
					onClick={onClickBackToLibrary}
				>
					<span className={styles.icon}>
						<Icon
							testId="back-library-enrichment-previewer"
							type="arrow-back"
							color={ColorBluePrimaryDark}
						/>
					</span>
					<div className={styles.text}>{t('enriching.step.button.backToLibrary')}</div>
				</div>
			) : null}
			<div className={styles.headerEnrichmentPreviewer}>
				<div className={styles.titleEnrichment} data-testid="enrichment-title-template">
					{enrichmentTitle ? enrichmentTitle : t('enriching.step.title.previewer')}
				</div>
				{enrichmentLibraryEnabled && !readonly && isSaveButtonEnabled ? (
					<div className="wrapper-button-save">
						<DNBButton
							size="default"
							variant="secondary"
							onClick={() => {
								dispatch(syncElementsInPreview())
								setIsEnrichmentSavingModalOpen(true)
								setIsUpdateButtonEnabled(false)
								setIsSaveButtonEnabled(false)
							}}
							data-testid="button-save-library"
						>
							{t('enriching.step.button.saveLibrary')}
						</DNBButton>
					</div>
				) : undefined}
			</div>
			{enrichmentLibraryEnabled && projectWizardState.currentProject.currentTemplate?.templateName ? (
				<div>
					<span data-testid="template-name" className={styles.templateName}>
						{projectWizardState.currentProject.currentTemplate.templateName}
					</span>
					{userOwnsTemplate() ? (
						<>
							&nbsp;
							{projectWizardState.enrichmentPreviewSubStep.isUpdatingOrSaving ? (
								<label data-testid="updating-template" className={styles.updateEnrichmentLink}>
									{t('enriching.step.link.updating')}
								</label>
							) : isUpdateButtonEnabled ? (
								<label
									data-testid="update-template"
									className={styles.updateEnrichmentLink}
									onClick={() => {
										updateEnrichmentTemplate()
									}}
								>
									{t('enriching.step.link.updateTemplate')}
								</label>
							) : undefined}
						</>
					) : undefined}
				</div>
			) : undefined}

			<div data-testid="items-selected" className={styles.itemsSelected}>
				{t('enriching.step.text.selected', { selected: selectedCount() })}
			</div>
			{readonly &&
				requiredFamilyTreeQuery.data?.notEntitled &&
				requiredFamilyTreeQuery.data.notEntitled.length > 0 && (
					<EnrichmentPreviewPanel
						elements={requiredFamilyTreeQuery.data.notEntitled as Array<EnrichmentPreviewPanelElement>}
						title={t('enriching.modal.unavailable.title', {
							unavailable: requiredFamilyTreeQuery.data.notEntitled.length
						})}
						displayCheckBoxes={false}
					/>
				)}
			{!readonly &&
				isC4SBeta &&
				isCRMSource &&
				projectWizardState.currentProject.thirdPartyIntegration?.crmSource == 'account' &&
				((familyTreeElements && familyTreeElements.length > 0) ||
					(requiredFamilyTreeQuery.data?.notEntitled &&
						requiredFamilyTreeQuery.data.notEntitled.length > 0)) && (
					<EnrichmentPreviewPanel
						elements={familyTreeElements}
						notEntitledElements={requiredFamilyTreeQuery.data?.notEntitled}
						title={t('enriching.modal.family.tree.required.title', {
							required:
								(familyTreeElements.length || 0) +
								(requiredFamilyTreeQuery.data?.notEntitled?.length || 0)
						})}
						displayCheckBoxes={true}
						onAddElement={onAddElementFromPanel}
						onRemoveElement={onRemoveElementFromPanel}
					/>
				)}
			{!readonly &&
				connectManageModule &&
				projectWizardState.currentProject.source.isFile &&
				((connectManageElements && connectManageElements.length > 0) ||
					(requiredConnectManageQuery.data?.notEntitled &&
						requiredConnectManageQuery.data.notEntitled.length > 0)) && (
					<EnrichmentPreviewPanel
						elements={connectManageElements}
						notEntitledElements={requiredConnectManageQuery.data?.notEntitled}
						title={t('enriching.modal.connect.manage.required.title', {
							required:
								(connectManageElements.length || 0) +
								(requiredConnectManageQuery.data?.notEntitled?.length || 0)
						})}
						displayCheckBoxes={true}
						onAddElement={onAddElementFromPanel}
						onRemoveElement={onRemoveElementFromPanel}
						description={t('enriching.modal.connect.manage.required.description') || ''}
						type={'warning'}
					/>
				)}
			<div className={styles.containerDescriptionEnrichmentPreviewer}>
				<span className={styles.descriptionEnrichmentPreviewer}>
					{t('enriching.step.description.previewer')}
				</span>
			</div>
			{canGenerateJsonEnrichingStep && (
				<div className={styles.containerJsonOutput}>
					<div className={styles.containerImage}>
						<img className={styles.imgInfo} src={information} alt={'information'} />
					</div>
					<div className={styles.containerTextCheck}>
						<span>{t('enriching.description.json.output')}</span>
						<div className={styles.containerCheckboxJsonOutput}>
							<CheckBox
								label={t('enriching.checkbox.text.json.output') as string}
								id={'checkbox-json-output'}
								checked={isEnabledJsonOutput}
								testId={'checkbox-json-output'}
								onChange={() => onJsonOutputChange()}
							/>
						</div>
					</div>
				</div>
			)}
			{!readonly &&
				showTradeUp &&
				projectWizardState.currentProject.currentTemplate &&
				projectWizardState.currentProject.enrichingApproach === 'saved-template' && (
					<TradeUpSelector
						enableTradeUp={enableTradeUp}
						selectedTradeUpEntity={selectedTradeUpEntity}
						onChangeTradeUpEntity={onChangeTradeUpEntity}
						onChangeTradeUp={tradeUpOnChange}
						testId={'tradeUpSelector-enrichmentPreviewer'}
					/>
				)}
			<div className={styles.searchButtonSection}>
				{!readonly ? (
					<div
						className={styles.searchButtonContainer}
						onClick={() => {
							dispatch(syncElementsInPreview())
							setIsAllDataBlockSearcherOpen(true)
							setEvaluatedElementLayout(0)
						}}
					>
						<label className={styles.searchLabel}>{t('select.your.data.search.label')}</label>
						<div className={styles.searchIcon}>
							<Icon type={'search'} color={ColorGrayDarker} />
						</div>
					</div>
				) : null}
			</div>
			<Tabs
				value={tabBlocks}
				onChange={(idx) => setTabBlocks(idx)}
				maxVisibleTabs={2}
				showDots={true}
				colors={{ defaultColor: ColorBluePrimaryDark, hoverColor: ColorBluePrimary }}
				testId={'tab-control-enrichment'}
			>
				<Tab
					key="tabItemAll0"
					label={t('text.all.datablocks') as string}
					id="tabAllBlocks"
					aria-controls="allBlocks"
				/>
				{projectWizardState.currentProject.enrichingLayout
					.sort(sortDataBlockList)
					.filter((block) => block.elements.length > 0)
					.filter(filterBlockListForSalesforce)
					.map(
						(block, index) =>
							block.elements &&
							block.elements.length && (
								<Tab
									key={'tab' + (index + 1)}
									label={t(block.blockId) as string}
									id={`tabBlock-${block.blockId}`}
									aria-controls={block.blockId}
								/>
							)
					)}
			</Tabs>
			<TabPanel value={tabBlocks} index={0} id="allBlocks" aria-labelledby="tabAllBlocks">
				<div className={styles.space} />
				<div className={styles.allBlocks}>
					<div className={styles.containerDataBlockPreviewerEnrichment}>{getDataBlocks()}</div>
				</div>
			</TabPanel>
			{projectWizardState.currentProject.enrichingLayout
				.sort(sortDataBlockList)
				.filter((block) => block.elements.length > 0)
				.filter(filterBlockListForSalesforce)
				.map((block, index) => {
					const tabPanelIndex = index + 1
					const defaultExist = defaultElements.find(
						(defaultElement) => defaultElement.blockId === block.blockId
					)
					return (
						block.elements &&
						block.elements.length && (
							<TabPanel
								key={'tabPanel' + (index + 1)}
								value={tabBlocks}
								index={tabPanelIndex}
								id={block.blockId}
								aria-labelledby={`tabBlock-${block.blockId}`}
							>
								<div className={styles.space} />
								<div className={styles.containerDataBlockPreviewerEnrichment}>
									<DataBlockPreviewerEnrichment
										selectedElements={block.elements}
										key={'block-' + block.blockId}
										defaultElements={defaultExist ? defaultExist.elements : undefined}
										readOnly={readonly}
										blockId={block.blockId}
									/>
								</div>
							</TabPanel>
						)
					)
				})}
			{isCRMSource && projectWizardState.currentProject.thirdPartyIntegration && (
				<div className={styles.footer}>
					<div className={styles.progressBar}>
						<AdjustableProgressBar
							totalRequirements={fieldUsage.fieldLimit}
							satisfiedRequirements={fieldUsage.existingFields}
							newRequirements={fieldUsage.newFields}
							remaining={fieldUsage.remainingFields}
							progressBarWidth={421}
						/>
					</div>
				</div>
			)}
			{!isValidIntegrationEnrichmentLayout ? (
				<div className={styles.errorMessage}>{t('enriching.step.progress.bar.errorMessage')}</div>
			) : null}
		</div>
	)
}

export { EnrichmentPreviewer }
