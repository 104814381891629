import { WizardContext } from '../../../components/wizardComposer/helpers/WizardContext'
import {
	WizardComposerStepDefinition,
	WizardComposerSubStepStates
} from '../../../components/wizardComposer/types/WizardComposerStepDefinition'
import world from '../../../local-core-ui/assets/data/world.json'
import {
	createConnectManageSource,
	createOrUpdateMatchRules,
	createOrUpdateSource
} from '../../../store/projectWizard/thunks'
import { MatchRuleInfo, ProjectWizardState, SPECIAL_RULE } from '../../../store/projectWizard/types'
import { EntityType } from '../../../types/sources/EntityType'
import { WizardComposerChooseMatch } from './choose-match/WizardComposerChooseMatch'
import { MatchConnectManage } from './match-connect-manage/match-connect-manage'
import { WizardComposerMatching } from './match-step/WizardComposerMatching'
import { MatchSummary } from './match-summary/match-summary'

const isMatchRulesReadyToValidate = (matchRules: Array<MatchRuleInfo>): boolean => {
	let allRequiredFieldsFilled = true
	if (matchRules.length > 0) {
		let totalSelected = 0
		for (const matchRuleInfo of matchRules) {
			totalSelected += matchRuleInfo.matchRule.allowedValues?.length || 0
			if (
				matchRuleInfo.matchRule.ruleType === SPECIAL_RULE &&
				(!matchRuleInfo.matchRule.allowedValues || matchRuleInfo.matchRule.allowedValues.length <= 0)
			) {
				allRequiredFieldsFilled = false
				break
			}
		}
		if (totalSelected === world.features.length) {
			allRequiredFieldsFilled = false
		}
	} else {
		allRequiredFieldsFilled = false
	}

	return allRequiredFieldsFilled
}

export const WizardComposerMatchingStepDefinition = (context: WizardContext): WizardComposerStepDefinition => {
	function baseRuleChanged(projectWizardState: ProjectWizardState): boolean {
		if (projectWizardState.currentProject.currentMatchConfigTemplate?.templateId) {
			return true
		} else {
			const baseRule = projectWizardState.currentProject.matchRules[0]
			return (
				baseRule?.advancedSettingsCollapsed === false ||
				projectWizardState.currentProject.matchRules.length > 1 ||
				baseRule?.matchRule.displayName !== 'Global Rule' ||
				baseRule?.matchRule.acceptCriterion.LowestConfidenceCode !== 6
			)
		}
	}

	function isNextEnabled(projectWizardState: ProjectWizardState): boolean {
		return (
			projectWizardState.currentProject.matchRules.length > 0 &&
			isMatchRulesReadyToValidate(projectWizardState.currentProject.matchRules) &&
			!projectWizardState.loadingNextStep
		)
	}

	function isNextEnabledConnectManage(projectWizardState: ProjectWizardState): boolean {
		if (
			projectWizardState.currentProject.source.enableConnectManage === true ||
			projectWizardState.currentProject.source.enableConnectManage === undefined
		) {
			return !!(
				projectWizardState.currentProject.source.connection_name &&
				projectWizardState.currentProject.source.external_source_id
			)
		} else return true
	}

	return {
		title: 'project.wizard.step.title.configure.match',
		progress: () =>
			context.projectWizardState.currentProject.matchRules.length > 0 &&
			context.projectWizardState.currentProject.matchRulesValidated
				? 100
				: isMatchRulesReadyToValidate(context.projectWizardState.currentProject.matchRules) &&
				  context.projectWizardState.currentProject.matchingApproach
				? 50
				: 0,
		onNext: async () => {
			await context
				.dispatch(createOrUpdateMatchRules())
				.then()
				.catch((err) => {
					throw err
				})
		},
		onPrevious: undefined,
		subSteps: [
			{
				title: 'project.wizard.step.choose.match',
				state: () =>
					context.projectWizardState.currentProject.matchingApproach !== undefined
						? WizardComposerSubStepStates.complete
						: WizardComposerSubStepStates.pending,
				onNext: undefined,
				onPrevious: undefined,
				isNextEnabled: () => baseRuleChanged(context.projectWizardState),
				route: { path: '/chooseMatch', component: WizardComposerChooseMatch }
			},
			{
				title: 'file.matching.matching',
				state: () =>
					context.projectWizardState.currentProject.matchRulesValidated
						? WizardComposerSubStepStates.complete
						: WizardComposerSubStepStates.pending,
				onNext: async () => {
					await context
						.dispatch(createOrUpdateMatchRules())
						.then(async () => {
							if (context.projectWizardState.currentProject.source.entityType === EntityType.CONTACTS)
								await context
									.dispatch(
										createOrUpdateSource(false, context.enableApi, context.salesforcePlatform)
									)
									.then(() => {
										context.queryClient.invalidateQueries([
											'getSources',
											{
												sourceId: context.projectWizardState.currentProject.source.id,
												inflateMetadata: false
											}
										])
										context.queryClient.invalidateQueries([
											'getProjects',
											context.projectWizardState.currentProject.id
										])
									})
						})
						.catch((err) => {
							throw err
						})
				},
				onPrevious: undefined,
				isNextEnabled: () => isNextEnabled(context.projectWizardState),
				route: { path: '/matching', component: WizardComposerMatching }
			},
			{
				title: 'matching.step.summary.title',
				state: () => {
					return WizardComposerSubStepStates.pending
				},
				onNext: undefined,
				onPrevious: undefined,
				isNextEnabled: () => {
					return true
				},
				route: { path: '/summary', component: MatchSummary }
			},
			...(context.isManage && context.projectWizardState.currentProject.source.isFile
				? [
						{
							title: 'matching.step.connect.manage',
							state: () => {
								return WizardComposerSubStepStates.pending
							},
							onNext: context.projectWizardState.currentProject.source.enableConnectManage
								? async () => {
										await context
											.dispatch(createConnectManageSource(context.passDelimiterInfo))
											.then(() =>
												context.queryClient.invalidateQueries([
													'getSources',
													{
														sourceId: context.projectWizardState.currentProject.source.id,
														inflateMetadata: false
													}
												])
											)
											.catch((err) => {
												throw err
											})
								  }
								: undefined,
							onPrevious: undefined,
							isNextEnabled: () => {
								return isNextEnabledConnectManage(context.projectWizardState)
							},
							route: { path: '/connect_manage', component: MatchConnectManage }
						}
				  ]
				: [])
		]
	}
}
