import { DNBButton, DNBRadio } from '@dnb-uux-design-system/react'
import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import greenCheck from '../../assets/images/icon-green-check.svg'
import UploadParser from '../../helpers/uploadParser'
import { Grid } from '../../local-core-ui'
import { useAppDispatch } from '../../store'
import { updateCurrentProjectAction } from '../../store/projectWizard/actions'
import { Spinner } from '../spinner/spinner'
import { FilePreviewerDelimiter } from './file-previewer-delimiter'
import styles from './file-previewer.module.scss'

interface FilePreviewerProps {
	title?: string
	titleSuffix?: string
	headers?: string[]
	data?: string[][]
	percentageUploaded?: number
	uploadingFile?: boolean
	onConfirm?: () => void
	onCancel?: () => void
	fileAlreadyProcessed?: boolean
	detectedDelimiter?: string
	file?: File
	testId: string
	showFileDelimiters?: boolean
	disableFileDelimiterChanges?: boolean
	isModeMixedFileActivated?: boolean
	onChangeQMapDUNS?: (mapDUNS: string) => void
	wantMapDunsField?: boolean
	totalRecords?: number
	totalRecordsSuffix?: string
}

export function FilePreviewer({
	title,
	titleSuffix,
	headers,
	data,
	percentageUploaded,
	uploadingFile,
	onConfirm,
	onCancel,
	fileAlreadyProcessed,
	detectedDelimiter,
	file,
	testId,
	showFileDelimiters = false,
	disableFileDelimiterChanges = false,
	isModeMixedFileActivated = false,
	onChangeQMapDUNS,
	wantMapDunsField = false,
	totalRecords,
	totalRecordsSuffix
}: FilePreviewerProps): ReactElement {
	const { t } = useTranslation()
	const [previewHeaders, setPreviewHeaders] = useState<string[]>(headers != undefined ? headers : [])
	const [previewData, setPreviewData] = useState<string[][]>(data != undefined ? data : [])

	const [delimiter, setDelimiter] = useState(detectedDelimiter || ',')
	const [identifier, setIdentifier] = useState<string>('"')
	const [escape, setEscape] = useState<string>('\\')
	const [mapDUNSMixedFile, setMapDUNSMixedFile] = useState<boolean>(wantMapDunsField)
	const delimiterError = detectedDelimiter === 'NOT_SUPPORTED'

	const dispatch = useAppDispatch()

	useEffect(() => {
		if (file !== undefined && file.name !== undefined) {
			const fileInfo = {
				fileInfo: {
					delimiter,
					autoDetectedDelimiter: detectedDelimiter,
					textIdentifier: identifier,
					escapeCharacter: escape
				}
			}
			dispatch(updateCurrentProjectAction(fileInfo))
		}
		/**
		 * dispatch is not added as a dependency because it would cause the other dependencies to change with each render.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [delimiter, detectedDelimiter, identifier, escape, file])

	const onDelimiterChange = (selectedDelimiter: string) => {
		setDelimiter(selectedDelimiter)
	}
	const onIdentifierChange = (selectedIdentifier: string) => {
		setIdentifier(selectedIdentifier)
	}
	const onEscapeChange = (selectedEscape: string) => {
		setEscape(selectedEscape)
	}

	const updatePreview = async (escape: string, delimiter: string) => {
		if (file !== undefined && file.name !== undefined) {
			// file.name !== undefined is to prevent weird scenario where file is {}
			const results = await UploadParser.parse(file, 20, delimiter)

			setPreviewHeaders(results[0])
			results.splice(0, 1)
			setPreviewData(results)
		}
	}

	useEffect(() => {
		updatePreview(escape, delimiter)
		/**
		 * updatePreview is not added as a dependency because it would cause the other dependencies to change with each render.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [delimiter, identifier, escape])

	return (
		<Grid testId="container-file-previewer" container>
			<Grid testId="upload-panel-file-previewer">
				<div className={styles.uploadPanel}>
					<div className={styles.uploadPanelHeader}>
						{showFileDelimiters ? (
							<h1>{t('file.previewer.line.0')}</h1>
						) : (
							<>
								<h1 className={styles.fileName} title={title}>
									{title}
								</h1>
								&nbsp;
								<h1 className={styles.previewText}>{titleSuffix}</h1>
							</>
						)}
					</div>
					{showFileDelimiters ? (
						FilePreviewerDelimiter(
							delimiter,
							testId,
							onDelimiterChange,
							onIdentifierChange,
							onEscapeChange,
							disableFileDelimiterChanges || fileAlreadyProcessed || percentageUploaded === 100
						)
					) : (
						<></>
					)}
					{isModeMixedFileActivated && (
						<div className={styles.questionMappingDuns}>
							<h5>{t('question.modal.want.map.duns')}</h5>
							<div>
								<DNBRadio
									label={t('response.yes.modal.want.map.duns') as string}
									value={'true'}
									size={'small'}
									checked={mapDUNSMixedFile}
									data-testid={'radio-true-map-duns'}
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										if (onChangeQMapDUNS) {
											onChangeQMapDUNS(e.target.value)
											setMapDUNSMixedFile(e.target.value === 'true')
										}
									}}
								/>
								<DNBRadio
									label={t('response.no.modal.want.map.duns') as string}
									value={'false'}
									size={'small'}
									checked={!mapDUNSMixedFile}
									data-testid={'radio-no-map-duns'}
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										if (onChangeQMapDUNS) {
											onChangeQMapDUNS(e.target.value)
											setMapDUNSMixedFile(e.target.value === 'true')
										}
									}}
								/>
							</div>
						</div>
					)}
					{showFileDelimiters ? (
						<div className={styles.delimiterPreviewContainer}>
							<p className={styles.delimiterTextContainer}>
								<span className={styles.delimiterSuffixText}>{titleSuffix}:</span>
								&nbsp;
								<span className={styles.delimiterValueText} title={title}>
									<b>{title}</b>
								</span>
							</p>
							{totalRecords && totalRecords > 0 ? (
								<p className={styles.delimiterTextContainer}>
									<span className={styles.delimiterSuffixText}>{totalRecordsSuffix}:</span>
									&nbsp;
									<span className={styles.delimiterValueText}>
										<b>{new Intl.NumberFormat().format(totalRecords)}</b>
									</span>
								</p>
							) : null}
						</div>
					) : (
						<></>
					)}
					<div className={styles.itemsTableContainer}>
						{fileAlreadyProcessed ? (
							<p className={styles.filePreviouslyUploaded}>{t('file.previewer.previously.uploaded')}</p>
						) : delimiterError ? (
							<p className={styles.filePreviouslyUploaded}>{t('upload.chooser.modal.delimiter.error')}</p>
						) : (
							<table>
								<thead>
									<tr>
										{previewHeaders?.map((headerText, index) => (
											<th key={index}>{headerText}</th>
										))}
									</tr>
								</thead>
								<tbody data-private={true}>
									{previewData?.map((item, index) => (
										<tr key={index}>
											{item.map((columnData, colIndex) => (
												<td key={'' + index + '-' + colIndex}>{columnData}</td>
											))}
										</tr>
									))}
								</tbody>
							</table>
						)}
					</div>
					<p className={styles.uploadNote}>{t('file.previewer.line.2')}</p>
					<p className={styles.uploadNote}>{t('file.previewer.line.3')}</p>
					{percentageUploaded !== 100 ? (
						<div className={styles.previewButtonsContainer}>
							<div className={styles.uploadButtonSection}>
								{uploadingFile ? (
									<div data-testid="upload-spinner" className={styles.uploadSpinner}>
										<Spinner />
									</div>
								) : (
									<DNBButton
										size="default"
										variant="primary"
										onClick={() => {
											if (onConfirm) {
												onConfirm()
											}
										}}
										data-testid={testId + '-upload'}
										disabled={delimiterError}
									>
										{t('file.previewer.upload.button')}
									</DNBButton>
								)}
							</div>

							<DNBButton
								size="default"
								variant="secondary"
								onClick={() => {
									if (onCancel) onCancel()
								}}
								data-testid={testId + '-cancel'}
								disabled={uploadingFile}
							>
								{t('file.previewer.cancel.button')}
							</DNBButton>
						</div>
					) : (
						<div className={styles.uploadCompleteContainer}>
							<img
								data-testid="check-file-previewer"
								alt="checkImage"
								src={greenCheck}
								className={styles.uploadCompleteImage}
							/>
							<p data-testid="upload-complete-text" className={styles.uploadCompleteText}>
								{t('file.previewer.upload.complete')}
							</p>
						</div>
					)}
				</div>
			</Grid>
		</Grid>
	)
}
