import { TFunction } from 'i18next'
import { ConnectionStatus } from '../../../queries/api/getC4SActiveConnection'
import { System } from '../../../queries/api/getConnections'

const getConnectionSnackbarText = ({
	connectionType,
	connectionStatus,
	tenantDisplayName,
	t
}: {
	connectionType: string
	connectionStatus: ConnectionStatus | null
	tenantDisplayName?: string
	t: TFunction
}): string => {
	if (connectionType === System.ConnectManage) {
		switch (connectionStatus) {
			case ConnectionStatus.Ready:
				return t('create.connect.success.message', { manageTenantName: tenantDisplayName })
			case ConnectionStatus.Unknown:
				return t('create.connect.unhealthy.message', { manageTenantName: tenantDisplayName })
			case ConnectionStatus.Failed:
			default:
				return t('create.connect.failed.message')
		}
	} else {
		switch (connectionStatus) {
			case ConnectionStatus.Ready:
				return t('create.salesforce.success.message')
			case ConnectionStatus.Unknown:
				return t('create.salesforce.unhealthy.message')
			case ConnectionStatus.Failed:
			default:
				return t('create.salesforce.failed.message.alt')
		}
	}
}

const getConnectionSnackbarVariant = (connectionStatus: ConnectionStatus | null) => {
	switch (connectionStatus) {
		case ConnectionStatus.Ready:
			return 'success'
		case ConnectionStatus.Unknown:
			return 'warning'
		case ConnectionStatus.Failed:
		default:
			return 'error'
	}
}

export { getConnectionSnackbarText, getConnectionSnackbarVariant }
